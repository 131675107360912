* {
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
}

@font-face {
  font-family: Montserrat;
  src: url("../../../public/Montserrat-VariableFont_wght.ttf");
}
@font-face {
  font-family: Strike;
  src: url("../../../public/ProtestStrike-Regular.ttf");
}

.user-management {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
  padding: 100px 20px 40px 300px;
  /* background-color: red; */
}

.user-management > .switch {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
}

.user-management > .switch > button {
  background: transparent;
  border: none;
  cursor: pointer;
  font-family: Montserrat;
  color: #202020;
  font-size: 16px;
}

.user-management > .switch button.active-btn {
  border-bottom: 2px solid #1f4798;
  padding-bottom: 10px;
  /* color: white; */
}

.user-management > .info-card {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  /* background-color: pink; */
}
.user-management > .info-card .user-details {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin-top: 20px;
  /* background-color: orange; */
}

.user-management > .info-card .user-details > .btn {
  width: 55%;
  display: flex;
  justify-content: space-between;
}

.user-management > .info-card .user-details > .btn > span {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.user-management > .info-card .user-details > .btn > span > p {
  color: #284697;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  line-height: 83.523%;
}

.user-management > .info-card .user-details > .btn > button {
  border: none;
  cursor: pointer;
  background: #284697;
  font-family: Montserrat;
  color: #fff;
  font-weight: 600;
  width: 150px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  gap: 5px;
}

.user-management > .info-card .user-details > .wrapper {
  width: 55%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 15px;
  margin-top: 20px;
  border: 1px solid #e0e0e0;
  padding: 30px 20px;
  border-radius: 20px;
}

.user-management > .info-card .user-details > .wrapper > .user {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.user-management > .info-card .user-details > .wrapper > p {
  font-family: Montserrat;
  font-weight: 800;
  color: #202020;
  font-size: 18px;
}

.user-management > .info-card .user-details > .wrapper > .user > span {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 5px;
}

.user-management > .info-card .user-details > .wrapper > .user > span > p {
  font-family: Montserrat;
  font-weight: 400;
  color: #202020;
}

.user-management > .info-card .user-details > .wrapper > .user > span > input {
  width: 100%;
  height: 50px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  background: transparent;
  font-family: Montserrat;
  padding-left: 10px;
}

.user-management
  > .info-card
  .user-details
  > .wrapper
  > .user
  > span
  > .input-container {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #e0e0e0;
  padding: 10px;
  border-radius: 5px;
}

.user-management
  > .info-card
  .user-details
  > .wrapper
  > .user
  > span
  > .input-container
  > input {
  height: 100%;
  background: transparent;
  font-family: Montserrat;
  outline: none;
  border: none;
}

.user-management > .info-card > .user-details > button {
  width: 200px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #284697;
  border: none;
  font-weight: 600;
  font-family: Montserrat;
  border-radius: 30px;
  color: #fff;
  cursor: pointer;
  margin-top: 20px;
}

@media screen and (max-width: 896px) {
  .user-management {
    padding: 80px 10px 20px 10px;
  }

  .user-management > .info-card .user-details > .btn {
    width: 100%;
  }

  .user-management > .info-card .user-details > .btn > button {
    font-size: 12px;
  }

  .user-management > .info-card .user-details > .wrapper {
    width: 100%;
    padding: 10px;
  }

  .user-management > .info-card .user-details > .wrapper > .user {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
  }

  .user-management > .info-card > .user-details > button {
    width: 100%;
  }

  .user-management
    > .info-card
    .user-details
    > .wrapper
    > .user
    > span
    > .input-container
    > input {
    width: 100%;
  }
}
