* {
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
}

@font-face {
  font-family: Montserrat;
  src: url("../../../public/Montserrat-VariableFont_wght.ttf");
}
@font-face {
  font-family: Strike;
  src: url("../../../public/ProtestStrike-Regular.ttf");
}

.business_package_details {
  width: 100%;
  /* height: 100vh; */
  display: flex;
  justify-items: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
  padding: 100px 20px 40px 276px;
  /* background-color: green; */
}


.business_package_details  > h3 {
    color:#1f4798;
    font-family: Strike;
    font-size: 18px;
    font-weight: 600;
}

.business_package_details > .header {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.business_package_details > .header > .icon {
  font-size: 24px;
}

.business_package_details > .header > h3 {
  color: #202020;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 400;
}

.business_package_details > .wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 20px;
    border: 1px solid #E0E0E0;
    border-radius: 10px;
    padding: 20px;
}

.business_package_details > .wrapper > h3 {
    color: rgba(32, 32, 32, 0.60);
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 600;
}

.business_package_details > .wrapper > .card {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.business_package_details > .wrapper > .card > span {
width: 100%;
display: flex;
justify-content: flex-start;
align-items: flex-start;
flex-direction: column;
gap: 10px;
}


.business_package_details > .wrapper > .card > span > p {
    color: rgba(32, 32, 32, 0.80);
font-family: Montserrat;
font-size: 14px;
font-weight: 400;
}

.business_package_details > .wrapper > .card > span > h3 {
    color: #202020;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 600;
}

@media screen and (max-width:896px) {
    .business_package_details {
        padding: 70px 10px 10px 10px ;
    }

    .business_package_details > .wrapper {
        padding: 10px;
    }

    .business_package_details > .wrapper > .card {
        flex-direction: column;
        gap: 10px;
    }
}
