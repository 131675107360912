* {
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
}

@font-face {
  font-family: Montserrat;
  src: url("../../../public/Montserrat-VariableFont_wght.ttf");
}
@font-face {
  font-family: Strike;
  src: url("../../../public/ProtestStrike-Regular.ttf");
}

.business-nav-container {
  display: flex;
  width: 260px;
  height: 100vh;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
  background: #fbfaf3;
  flex-shrink: 0;
  padding: 10px 20px;
  position: fixed;
  z-index: 100;
}

.business-nav-container > .mobile-menus {
  display: none;
}

.business-nav-container > .links {
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-height: 600px;
  overflow-y: scroll;
}

.business-nav-container > .links > a {
  display: flex;
  width: 220px;
  height: 50px;
  padding: 10px;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  color: #284697;
  font-family: Strike;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-decoration: none;
}

.business-nav-container > .links > a > .icon {
  width: 26px;
  height: 26px;
}

@media screen and (max-width: 896px) {
  .business-nav-container {
    width: 100%;
    height: 60px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #e0e0e0;
  }

  .business-nav-container > a {
    text-decoration: none;
    height: 100%;
  }

  .business-nav-container > a > .company-logo {
    width: 60px;
    height: 100%;
  }

  .business-nav-container > .links {
    width: 100%;
    max-height: 100vh;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 0px;
    top: 60px;
    left: 0%;
    position: absolute;
    background: #fbfaf3;
    transform: translateX(100%);
    transition: 0.3s ease-in-out;
    padding: 10px 10px 100px 10px;
    overflow-y: scroll;
  }

  .business-nav-container > .links > a {
    width: 100%;
  }

  .business-nav-container > .links.mobile.active {
    transform: translateX(0%);
    transition: 0.3s ease-in-out;
  }

  .business-nav-container > .mobile-menus {
    display: block;
  }
}

.business_admin_header {
  width: 100%;
  background-color: white;
  position: fixed;
  right: 0%;
  top: 0%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  height: 80px;
  border-bottom: 1px solid #eeeeee;
  z-index: 100;
  padding: 0px 20px 0px 276px;
}

.business_admin_header > p {
  color: #202020;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 400;
}

.business_admin_header > p > span {
  color: #284697;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  margin: 0px 5px 0px 0px;
}

.business_admin_header > .user-details > .card-1 {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.business_admin_header > .user-details > .card-1 > .circle {
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background: #284697;
  color: white;
  font-family: Strike;
  font-size: 12px;
  padding: 5px;
}

.business_admin_header > .user-details {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  position: relative;
}

.business_admin_header > .user-details > .card-2 {
  width: 330px;
  top: 60px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 15px;
  position: absolute;
  padding: 10px;
  background: #fff;
  z-index: 100;
  right: 0%;
  border-bottom: 2px solid #ffcd00;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  box-shadow: 0px 10px 24px 0px rgba(0, 0, 0, 0.07);
  max-height: 0;
  opacity: 0;
  transform: translateY(-10px);
  overflow: hidden;
  transition: max-height 0.4s ease-in-out, opacity 0.3s ease-in-out,
    transform 0.3s ease-in-out;
}

.business_admin_header > .user-details > .card-2.open {
  max-height: 300px;
  opacity: 1;
  transform: translateY(0);
}

.business_admin_header > .user-details > .card-2 > .drop-card {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.business_admin_header > .user-details > .card-2 > span {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.business_admin_header > .user-details > .card-2 > span > p {
  font-family: Montserrat;
  font-size: 10px;
  color: #202020;
  font-weight: 400;
}
.business_admin_header > .user-details > .card-2 > span > h3 {
  font-family: Montserrat;
  font-size: 12px;
  color: #202020;
  font-weight: 600;
}

.business_admin_header > .user-details > .card-2 > button {
  background: #d0300e;
  border: none;
  cursor: pointer;
  color: white;
  padding: 5px;
  border-radius: 5px;
}

.business-nav-container > .links > .mobile-user-drop {
  display: none;
  width: 100%;
}

@media screen and (max-width: 896px) {
  .business_admin_header {
    display: none;
  }

  .business-nav-container > .links > .mobile-user-drop {
    display: block;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;
  }

  .business-nav-container > .links > .mobile-user-drop > .profile {
    display: flex;
    gap: 10px;
  }

  .business-nav-container > .links > .mobile-user-drop > .profile > .circle {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-content: center;
    background: #284697;
    color: white;
    font-family: Strike;
    padding: 10px;
    border-radius: 5px;
  }

  .business-nav-container > .links > .mobile-user-drop > .profile > .card-1 {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .business-nav-container
    > .links
    > .mobile-user-drop
    > .profile
    > .card-1
    > .wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  .business-nav-container
    > .links
    > .mobile-user-drop
    > .profile
    > .card-1
    > .wrapper
    > span {
    font-family: Strike;
    font-size: 20px;
  }
  .business-nav-container
    > .links
    > .mobile-user-drop
    > .profile
    > .card-1
    > .wrapper
    > p {
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 400;
  }

  .business-nav-container > .links > .mobile-user-drop > .card-2 {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    border-top: 1px solid #e0e0e0;
    border-bottom: 1px solid #e0e0e0;
    padding: 10px 0px;
  }

  .business-nav-container > .links > .mobile-user-drop > .card-2 > .drop-card {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .business-nav-container
    > .links
    > .mobile-user-drop
    > .card-2
    > .drop-card
    > h3 {
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 400;
    color: #202020;
  }

  .business-nav-container > .links > .mobile-user-drop > .card-2 > .wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;
    max-height: 0;
    overflow: hidden;
    opacity: 0;
    transition: max-height 0.4s ease-in-out, opacity 0.3s ease-in-out;
  }

  .business-nav-container
    > .links
    > .mobile-user-drop
    > .card-2
    > .wrapper.open {
    max-height: 500px;
    opacity: 1;
  }

  .business-nav-container
    > .links
    > .mobile-user-drop
    > .card-2
    > .wrapper
    > span {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .business-nav-container
    > .links
    > .mobile-user-drop
    > .card-2
    > .wrapper
    > span
    > p {
    font-family: Montserrat;
    font-size: 10px;
    font-weight: 400;
    color: #202020;
  }

  .business-nav-container
    > .links
    > .mobile-user-drop
    > .card-2
    > .wrapper
    > span
    > h3 {
    font-family: Montserrat;
    font-size: 10px;
    font-weight: 600;
    color: #202020;
  }

  .business-nav-container
    > .links
    > .mobile-user-drop
    > .card-2
    > .wrapper
    > button {
    background: #d0300e;
    border: none;
    cursor: pointer;
    color: white;
    padding: 5px;
    border-radius: 5px;
  }
}
