* {
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
}

@font-face {
  font-family: Montserrat;
  src: url("../../../public/Montserrat-VariableFont_wght.ttf");
}
@font-face {
  font-family: Strike;
  src: url("../../../public/ProtestStrike-Regular.ttf");
}

.business_shipment {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
  padding: 100px 20px 40px 276px;
}

.business_shipment > span {
  display: none;
}

.business_shipment > .switch {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
}

.business_shipment > .switch > button {
  padding: 10px 30px;
  background: transparent;
  font-family: Montserrat;
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
  font-weight: 800;
  color: #202020;
}

.business_shipment > .switch button.active-btn {
  background-color: #d0480e;
  color: white;
}

@media screen and (max-width: 896px) {
  .business_shipment {
    padding: 80px 10px 10px 10px;
  }

  .business_shipment > span {
    display: block;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
  }

  .business_shipment > span > h3 {
    color:#1f4798;
    font-family: Strike;
    font-size: 20px;
    font-weight: 600;
  }
  .business_shipment > span > .shipment-icon {
    color:#1f4798;
    font-size: 20px;
  }

  .business_shipment > .switch > button {
    padding: 10px 20px;
    font-size: 12px;
  }
}
