
* {
    padding: 0%;
    margin: 0%;
    box-sizing: border-box;
  }
  
  @font-face {
    font-family: Strike;
    src: url(/public/ProtestStrike-Regular.ttf);
  }
  
  @font-face {
    font-family: Montserrat;
    src: url(/public/Montserrat-VariableFont_wght.ttf);
  }
  @font-face {
    font-family: Montserrat Alternates;
    src: url(/public/MontserratAlternates-Regular.ttf);
  }

  .business-admin-get-quote {
    padding: 100px 20px 40px 276px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: #f9f9f9;
  }

  .business-admin-get-quote > .get-form-container {
    width: 85%;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    background: #fff;
    padding: 50px;
    box-shadow: 0px 9px 34px 0px rgba(0, 0, 0, 0.07);
  }
  
  .business-admin-get-quote > .get-form-container > .getquote-con {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
  }

    
  .business-admin-get-quote > .get-form-container > form {
    width: 100%;
  }
  
  .business-admin-get-quote > .get-form-container > form > .input-combination {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 50px;
  }
  
  .business-admin-get-quote > .get-form-container > form > .input-combination > span {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  
  .business-admin-get-quote > .get-form-container > .getquote-con  > span {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .business-admin-get-quote > .get-form-container > .getquote-con > span > .card {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
  }

  .business-admin-get-quote > .get-form-container > .getquote-con  > span > .card > a {
    text-decoration: none;
    color: #202020;
    font-size: 24px;
    margin-top: 10px;
  }
  
  .business-admin-get-quote > .get-form-container > .getquote-con  > span > .card > h3 {
    color: #d0300e;
    font-family: Strike;
    font-size: 38px;
    font-weight: 700;
  }
  .business-admin-get-quote > .get-form-container > .getquote-con  > span > p {
    color: #202020;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 400;
  }
  
  .business-admin-get-quote > .get-form-container > form {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
  }

  .business-admin-get-quote > .get-form-container > form > .input-combination {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
  }
  
  .business-admin-get-quote > .get-form-container > form > .input-combination > span > label {
    width: 100%;
    color: #202020;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 400;
    margin: 10px 0px;
  }
  
  .business-admin-get-quote > .get-form-container > form > .input-combination > span > input {
    width: 100%;
    height: 50px;
    border-radius: 5px;
    border: 1px solid #e0e0e0;
    background: #fff;
    margin: 10px 0px;
    outline: none;
    color: #b5b5b5;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 400;
    padding-left: 10px;
    background-color: transparent;
  }

  .business-admin-get-quote > .get-form-container > form > .form-group-item-group  {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
  }

  .business-admin-get-quote > .get-form-container > form > .form-group-item-group > .input-combination {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
  }
  

  .business-admin-get-quote > .get-form-container > form > .form-group-item-group > .input-combination > span > label {
    width: 100%;
    color: #202020;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 400;
    margin: 10px 0px;
  }
  
  .business-admin-get-quote > .get-form-container > form > .form-group-item-group > .input-combination > span > input {
    width: 100%;
    height: 50px;
    border-radius: 5px;
    border: 1px solid #e0e0e0;
    background: #fff;
    margin: 10px 0px;
    outline: none;
    color: #b5b5b5;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 400;
    padding-left: 10px;
    background-color: transparent;
  }
  .business-admin-get-quote > .get-form-container > form > .input-combination > span > select {
    width: 100%;
    height: 50px;
    border-radius: 5px;
    border: 1px solid #e0e0e0;
    background: #fff;
    margin: 10px 0px;
    outline: none;
    color: #b5b5b5;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 400;
  }

  .super-remove-card {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .super-remove-card > button {
    border: none;
    background: #d0300e;
    padding:5px 7px;
    color: white;
    font-family: Montserrat;
    border-radius: 3px;
    cursor: pointer;
  }
  

  .super-add-item-card {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 10px;
  }
  .super-add-item-card > button {
    border: none;
    background:#284697;
    padding:5px 7px;
    color: white;
    font-family: Montserrat;
    border-radius: 3px;
    cursor: pointer;
  }
  
  .business-admin-get-quote > .get-form-container > form > button {
    width: 100%;
    height: 55px;
    border-radius: 30px;
    background: #d0300e;
    color: #fff;
    border: none;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 600;
    margin: 40px 0px 10px 0px;
    cursor: pointer;
  }

  
 .business-value-input {
  width: 48%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
  
.business-value-input > span {
  width: 100%;
  display: flex;
  flex-direction: column;
}

  
.business-value-input > span > label {
  width: 100%;
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  margin: 10px 0px;
}

  
.business-value-input > span > input {
  width: 100%;
  height: 50px;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
  background: #fff;
  margin: 10px 0px;
  outline: none;
  color: #b5b5b5;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  padding-left: 10px;
  background-color: transparent;
}



  .business-quote-pop-card {
    width:100%;
    height: 100%;
    position: fixed;
    top: 0%;
    z-index: 100;
    padding: 50px 0px;
    background: rgba(18, 18, 18, 0.6);
    padding-bottom: 10px;
  }
  
  .business-quote-pop-wrapper {
    width: 580px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 20px;
    background: #fff;
    margin: auto;
    gap: 10px;
    padding: 20px;
  }
  
  .business-quote-pop-wrapper > h3 {
    width: 420px;
    color: #202020;
    text-align: center;
    font-family: Strike;
    font-size: 26px;
    font-weight: 700;
    margin-top: 30px;
  }
  
  .business-quote-pop-wrapper > p {
    width: 420px;
    color: #202020;
    text-align: center;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 400;
    line-height: 123.023%;
  }
  
  
  .business-quote-pop-wrapper > span {
    width: 100%;
    display: flex;
    padding: 0px 70px;
    justify-content: space-between;
  }
  
  .business-quote-pop-wrapper > span > h3 {
    color: #202020;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 400;
  }
  
  .quote-package-icon {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 70px;
  }

  .business-get-qoute-cost {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    padding: 0px 50px;
  }
  
  
  .business-get-qoute-cost  > .wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  }

  .business-get-qoute-cost  > .wrapper > p {
    color: #202020;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 400;
  }

  .business-get-qoute-cost  > .wrapper > h3 {
    color: #202020;
    font-family: Strike;
    font-size: 16px;
    font-weight: 600;
  }

  .business-quote-pop-wrapper > button {
    width: 150px;
    height: 50px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 40px;
    border: none;
    cursor: pointer;
    text-decoration: none;
    color: #fff;
    background: #d0300e;
    margin: 20px 0px;
  }

  .toast-container {
    width: 45%;
  }
  
  
@media screen and (max-width:896px) {
    .business-admin-get-quote {
        padding:70px 10px 10px 10px;
        
    } 


    .business-admin-get-quote > .get-form-container {
        width: 100%;
        padding: 10px;
    }

    .business-admin-get-quote > .get-form-container > .getquote-con  > span > .card {
        gap: 5px;
    }

      
  .business-admin-get-quote > .get-form-container > .getquote-con  > span > .card > h3 {
    font-size: 16px;
    margin-top: 10px;
  }
        
  .business-admin-get-quote > .get-form-container > .getquote-con  > span > .card > a > .back {
    font-size: 16px;
  }

  .business-quote-pop-card {
    padding: 10px;
  }
  .business-quote-pop-card > .business-quote-pop-wrapper {
    width: 100%;
    padding: 10px;
  }

  .business-quote-pop-card > .business-quote-pop-wrapper > p {
    width: 100%;
  }

  .business-quote-pop-card > .business-quote-pop-wrapper > span {
    width: 100%;
    padding: 0px;
    flex-direction: column;
    gap: 15px;
  }

  .business-get-qoute-cost {
    padding: 0px;
  }

  .business-admin-get-quote > .get-form-container > form > .form-group-item-group > .input-combination {
    flex-direction: column;
    gap: 0px;
  }

  .business-admin-get-quote > .get-form-container > form > .input-combination {
    gap: 0px;
  }

    
 .business-value-input {
  width: 100%;
 }


}