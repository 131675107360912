.payment-verification-container * {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Montserrat', Arial, sans-serif; 
}

@font-face {
  font-family: 'Strike';
  src: url("../../../public/ProtestStrike-Regular.ttf");
}

@font-face {
  font-family: 'Montserrat';
  src: url("../../../public/Montserrat-VariableFont_wght.ttf");
}

.payment-verification-container {
  padding: 100px 20px 20px 270px;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: #f9f9f9;
}

.payment-verification-container > .notice {
  background: #fffae5;
  border: 1px solid #ffcc00;
  color: #996600;
  padding: 12px 20px;
  margin-bottom: 20px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  width: 90%;
  max-width: 400px;
}

.payment-verification-container > .verification-card {
  background: white;
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
  text-align: center;
  max-width: 400px;
  width: 90%;
}

/* Headings */
.payment-verification-container h1,
.payment-verification-container h2,
.payment-verification-container h3 {
  font-family: 'Strike', sans-serif;
  margin-top: 8px;
}

/* Loading State */
.payment-verification-container .loading-state {
  font-size: 18px;
  color: #555;
}

/* Icons */
.payment-verification-container .success-icon,
.payment-verification-container .error-icon,
.payment-verification-container .spinner {
  font-size: 50px;
}

/* Success Message */
.payment-verification-container .success-message {
  color: #2d9f2d;
}

/* Error Message */
.payment-verification-container .error-message {
  color: #d9534f;
}

/* Button Styling */
.payment-verification-container button {
  background: #007bff;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  transition: 0.3s ease-in-out;
  width: 100%;
  max-width: 300px;
  margin-top: 20px;
}

.payment-verification-container button:hover {
  background: #0056b3;
}

/* Animations */
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Responsive Styles */
@media (max-width: 768px) {
  .payment-verification-container {
    padding: 80px 15px;
  }

  .payment-verification-container .verification-card {
    padding: 20px;
    width: 95%;
  }

  .payment-verification-container .notice {
    font-size: 14px;
    width: 95%;
  }

  .payment-verification-container h2 {
    font-size: 20px;
  }

  .payment-verification-container p {
    font-size: 14px;
  }

  .payment-verification-container .spinner {
    font-size: 30px;
  }

  .payment-verification-container .success-icon,
  .payment-verification-container .error-icon {
    font-size: 40px;
  }

  .payment-verification-container button {
    font-size: 14px;
    padding: 10px;
  }
}

  