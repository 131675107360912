* {
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
}

@font-face {
  font-family: Montserrat;
  src: url("../../../public/Montserrat-VariableFont_wght.ttf");
}
@font-face {
  font-family: Strike;
  src: url("../../../public/ProtestStrike-Regular.ttf");
}

.business-overview-container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
  padding: 100px 20px 20px 276px;
}

.business-overview-container > .card-1 {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.business-overview-container > .card-1 > h3 {
  color: #000;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 600;
}

.business-overview-container > .card-1 > span {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  flex-direction: column;
  gap: 10px;
}

.business-overview-container > .card-1 > span > h3 {
  color: #000;
  text-align: right;
  font-family: Strike;
  font-size: 14px;
  font-weight: 400;
}

.business-overview-container > .card-1 > span > p {
  color: #000;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  line-height: 120.7%;
}

.business-overview-container > .card-2 {
    width: 100%;
    display: flex;
    justify-content:center;
    align-items: center;
    gap: 20px;
}

.business-overview-container > .card-2 > .quick-link {
    width: 100%;
    height: 120px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 15px;
    border: 1px solid rgba(0, 0, 0, 0.30);
    background: #FFF; 
    padding: 10px;
    text-decoration: none;
}

.business-overview-container > .card-2 > .quick-link > h3 {
color: #1A1A1A;
font-family: Montserrat;
font-size: 14px;
font-weight: 600;
line-height: 120.7%; 
}

.business-overview-container > .card-3 {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 20px;
}

.business-overview-container > .card-3 > span > h3 {
    color: #000;
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 600;
}

.business-overview-container > .card-3 > .wrapper {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 10px;
    row-gap: 10px;
}

.business-overview-container > .card-3 > .wrapper > .overview-box {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    border-radius: 15px;
border: 1px solid rgba(0, 0, 0, 0.30);
background: #FFF;
padding: 20px;
}

.business-overview-container > .card-3 > .wrapper > .overview-box > .circle {
display: flex;
width: 50px;
height: 50px;
padding: 13px;
justify-content: center;
align-items: center;
border-radius: 100%;
background: #F1E7DF;
}

.business-overview-container > .card-3 > .wrapper > .overview-box > .circle > .icons {
    color: #284697;
}

.business-overview-container > .card-3 > .wrapper > .overview-box > span {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;
}

.business-overview-container > .card-3 > .wrapper > .overview-box > span > p {
color: rgba(32, 32, 32, 0.80);
font-family: Montserrat;
font-size: 12px;
font-weight: 400;
}
.business-overview-container > .card-3 > .wrapper > .overview-box > span > h3 {
color: #1A1A1A;
text-align: center;
font-family: Montserrat;
font-size: 20px;
}

@media screen and (max-width:896px) {
    .business-overview-container {
        padding: 100px 10px 10px 10px;
    }

    .business-overview-container > .card-2 {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        overflow-x: scroll;
    }

    
.business-overview-container > .card-2 > .quick-link {
    flex-direction: column;
    min-width:90% ;
}

.business-overview-container > .card-2 > .quick-link > h3 {
    font-size: 16px;
    font-family: Strike;
}

.business-overview-container > .card-3 > .wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    overflow-x: scroll;
}


.business-overview-container > .card-3 > .wrapper > .overview-box {
    min-width: 90%;
}
}

