* {
    padding: 0%;
    margin: 0%;
    box-sizing: border-box;
  }
  
  @font-face {
    font-family: Montserrat;
    src: url("../../../public/Montserrat-VariableFont_wght.ttf");
  }
  @font-face {
    font-family: Strike;
    src: url("../../../public/ProtestStrike-Regular.ttf");
  }


  
.business-user-management {
  width: 100%;
  display: flex;
  flex-direction: column;
  /* background-color: pink; */
  }
  
  
  .business-user-management > .header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    width: 100%;
    height: 70px;
    padding: 20px 0px 10px 0px;
    border-bottom: 1px solid rgba(32, 32, 32, 0.4);
  }


    
  .business-user-management > .header > .secure-icon {
    font-size: 40px;
    background: #284697;
    border-radius: 50px;
    padding: 10px;
    color: #fff;
  }
  
  .business-user-management > .header > p {
    color: #284697;
    font-family: Montserrat;
    font-size: 24px;
    font-weight: 700;
    line-height: 83.523%;
  }
  
  
  .business-user-management > .header > .icon {
    cursor: pointer;
  }
  
  
  .business-user-management > .form-setting {
    width: 500px;
    border-radius: 20px;
    background: #fff;
    border: 1px solid #e0e0e0;
    padding: 30px 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 20px;
  }
  
  .business-user-management > .form-setting > label {
    display: flex;
    flex-direction: column;
    gap: 5px;
    color: #202020;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 400;
    width: 100%;
  }
  
  
  .business-user-management > .form-setting > label .inner {
    width: 100%;
    height: 50px;
    padding: 13px 10px;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    border: 1px solid #e0e0e0;
    background: #fff;
    display: flex;
    justify-content: space-between;
  }
  
  
  .business-user-management > .form-setting > label .inner > input {
    width: 100%;
    border: none;
    outline: none;
    padding: 20px;
    height: 40px;
  }
  
  
  .business-user-management > .form-setting > label .inner > .iconp {
    margin-right: 20px;
    cursor: pointer;
  }
  
  
  .business-user-management > .form-setting > button {
    display: flex;
    width: 100%;
    height: 50px;
    padding: 10px 50px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 38px;
    background: #d0300e;
    border: none;
    cursor: pointer;
    color: #fff;
    text-align: center;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 123.023%;
  }
  
  
  .business-user-management > .form-setting > button:disabled {
    background: #e25b3c;
    cursor: not-allowed;
  }
  
  
  .business-user-management > .form-setting > .error-message {
      color: #d0300e;
      font-family: Montserrat;
  }
  
  .business-user-management > .form-setting > .success-message {
      color: green;
      font-family: Montserrat;
  }
  
  
  @media screen and (max-width:896px) {
    .business-user-management {
      margin: 0%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      height: fit-content;
    }

    .business-user-management > .header {
      border: none;
    }

    .business-user-management > .header > p {
      font-family: Strike;
    }
  
      
.business-user-management > .form-setting {
      width: 97%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: auto;
      padding: 15px;
    }
  
     
.business-user-management > .form-setting > label {
      width: 100%;
    }
  
     
.business-user-management > .form-setting > label .inner {
      width: 100%;
    }
     
.business-user-management > .form-setting > button {
      width: 100%;
    }
  }
  