* {
    padding: 0%;
    margin: 0%;
    box-sizing: border-box;
  }
  
  @font-face {
    font-family: Montserrat;
    src: url("../../../public/Montserrat-VariableFont_wght.ttf");
  }
  @font-face {
    font-family: Strike;
    src: url("../../../public/ProtestStrike-Regular.ttf");
  }

  .add-icon {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .add-icon > .wrapper {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
  }

  .add-icon > .wrapper > .add-flow {
    width:80px;
    height: 5px;
    background: #284697;
    border-radius: 5px;
  }

  .add-icon > .wrapper > p {
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 400;
  }

  .add-icon > .wrapper > .upload-flow {
    width:80px;
    height: 5px;
    background: rgb(167, 167, 182);
    border-radius: 5px;
  }

  .add-icon > .wrapper > .creation-flow {
    width:80px;
    height: 5px;
    background: rgb(167, 167, 182);
    border-radius: 5px;
  }

  


