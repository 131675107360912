* {
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
}

@font-face {
  font-family: Montserrat;
  src: url("../../../public/Montserrat-VariableFont_wght.ttf");
}
@font-face {
  font-family: Strike;
  src: url("../../../public/ProtestStrike-Regular.ttf");
}

.international-package-creation {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  padding: 100px 20px 20px 276px;
}

.international-package-creation > .header {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.international-package-creation > .header > span {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
}

.international-package-creation > .header > span > p {
  color: #212121;
  font-family: Strike;
  font-size: 20px;
  font-weight: 600;
}

.international-package-creation > .item-creation {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 100px 10px 10px 10px;
}

.international-package-creation > .item-creation > h3 {
  color: #284697;
  font-family: Strike;
  font-size: 100px;
  font-weight: 800;
}

@media screen and (max-width: 896px) {
  .international-package-creation {
    padding: 80px 10px 10px 10px;
  }

  .international-package-creation > .item-creation > h3 {
    font-size: 40px;
  }
}
