* {
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
}

@font-face {
  font-family: Montserrat;
  src: url("../../../public/Montserrat-VariableFont_wght.ttf");
}

.logistic-pickups {
  padding-top: 100px;
  width: 81.9%;
  margin-left: 260px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #f9f9f9;
}

.logistic-pickups > .header {
  display: flex;
  width: 100%;
  height: 100px;
  padding: 20px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(32, 32, 32, 0.40);
}

.logistic-pickups > .header > p {
  color: #284697;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 700;
  line-height: 83.523%;
}

.logistic-pickups > .header > span {
  display: flex;
  width: 280px;
  height: 44px;
  padding: 12px;
  align-items: center;
  gap: 10px;
  border-radius: 30px;
  border: 1px solid rgba(32, 32, 32, 0.6);
}

.logistic-pickups > .header > span > input {
  border: none;
  width: 100%;
  background: #f9f9f9;
  color: rgba(32, 32, 32, 0.7);
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  outline: none;
}


.logistic-pickups > .pickup-table {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 20px;
    height: 600px;
    overflow-y: scroll;
    flex-direction: column;
}

.logistic-pickups > .pickup-table > p {
  color: #d0300e;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 700;
  line-height: 83.523%;
}

.logistic-pickups > .pickup-table > table {
  width: 100%;
}

.logistic-pickups > .pickup-table > table > thead > tr > th {
  text-align: start;
  height: 60px;
  padding: 15px;
  border-bottom: 1px solid #e0e0e0;
  background: #f5f5f5;
  color: #202020;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 600;
}

.logistic-pickups > .pickup-table > table > tbody > tr > td {
  height: 60px;
  padding: 15px;
  text-align: start;
  border-bottom: 1px solid #e0e0e0;
  background: #fff;
  color: #202020;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
}

.logistic-pickups > .pickup-table > table > tbody > tr > td > a {
  color:  #d0300e;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  text-decoration: none;
}
