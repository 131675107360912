* {
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
}

@font-face {
  font-family: Montserrat;
  src: url("../../../public/Montserrat-VariableFont_wght.ttf");
}

.overlay-re {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.overlay-message-re {
  color: white;
  font-size: 1.5rem;
  text-align: center;
  font-family: Montserrat;
}

.logistics-sender {
  padding: 100px 20px 20px 276px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #f9f9f9;
}

.logistics-sender > .header {
  display: flex;
  width: 100%;
  height: 50px;
  padding: 20px;
  align-items: center;
  border-bottom: 1px solid rgba(32, 32, 32, 0.4);
}

.logistics-sender > .header > p {
  color: #284697;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 700;
  line-height: 83.523%;
}

.logistics-sender > .ship-form,
.logistics-sender > .summary {
  width: 80%;
  align-items: center;
  border-radius: 20px;
  background: #fff;
  padding: 20px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.logistics-sender > .ship-form > form {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 30px;
  padding: 30px 0px;
}

.logistics-sender > .ship-form > form > p {
  color: #000;
  text-align: center;
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 500;
}

.logistics-sender > .ship-form > form > .inner {
  display: grid;
  grid-template-columns: repeat(2, 2fr);
  gap: 20px 50px;
}

.logistics-sender > .ship-form > form > .inner > label {
  display: flex;
  flex-direction: column;
  display: flex;
  width: 385px;
  border-radius: 5px;
  background: #fff;
  gap: 8px;
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
}

.logistics-sender > .ship-form > form > .inner > label > input {
  width: 100%;
  padding-left: 20px;
  height: 50px;
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
  background: #fff;
  outline: none;
}

.logistics-sender > .ship-form > form > .inner > label > textarea {
  display: flex;
  width: 385px;
  height: 100px;
  padding: 20px;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
  background: #fff;
  outline: none;
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
}
.logistics-sender > .ship-form > form > .inner > label > .num {
  display: flex;
  align-items: center;
}

.logistics-sender > .ship-form > form > .inner > label > .num > select {
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
  border: 1px solid #e0e0e0;
  background: #fff;
  height: 50px;
  outline: none;
}

.logistics-sender > .ship-form > form > .inner > label > .num > input {
  width: 100%;
  padding-left: 20px;
  height: 50px;
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  border: 1px solid #e0e0e0;
  background: #fff;
  outline: none;
}

.logistics-sender > .ship-form > form > .inner > label > select {
  width: 100%;
  padding-left: 20px;
  height: 50px;
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  border: 1px solid #e0e0e0;
  background: #fff;
  outline: none;
}

.logistics-sender > .ship-form > form > .add-more-item {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.logistics-sender > .ship-form > form > .add-more-item > button {
  width: 60px;
  height: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #284697;
  text-align: center;
  font-size: 24px;
  cursor: pointer;
  border: none;
  background: transparent;
}

.logistics-sender > .ship-form > form > .inner > .remove-item > button {
  width: 60px;
  height: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #d0300e;
  text-align: center;
  font-size: 24px;
  cursor: pointer;
  border: none;
  background: transparent;
}
.logistics-sender > .ship-form > form > button {
  width: 160px;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-align: center;
  font-size: 16px;
  cursor: pointer;
  border: none;
  background: #d0300e;
  border-radius: 38px;
  font-family: Montserrat;
  font-weight: 700;
}

.logistics-sender > .ship-form > form > .btn {
  display: flex;
  align-items: center;
  gap: 20px;
}

.logistics-sender > .ship-form > form > .btn > button:nth-child(1) {
  width: 160px;
  height: 55px;
  border-radius: 38px;
  background: #284697;
  color: #fff;
  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 700;
  line-height: 123.023%;
  cursor: pointer;
  border: none;
}
.logistics-sender > .ship-form > form > .btn > button:nth-child(2) {
  width: 160px;
  height: 55px;
  background: #d0300e;
  color: #fff;
  text-align: center;
  border-radius: 38px;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 700;
  line-height: 123.023%;
  cursor: pointer;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logistics-sender > .summary > .sender-info {
  display: flex;
  width: 820px;
  padding: 15px;
  align-items: center;
  flex-direction: column;
}
.logistics-sender > .summary > .sender-info > .in {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0px;
  border-bottom: 1px solid #284697;
}

.logistics-sender > .summary > .sender-info > .in > p {
  color: #000;
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 500;
}

.logistics-sender > .summary > .sender-info > .in > button,
.logistics-sender > .summary > .sum > .in > button {
  color: #284697;
  text-align: center;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 400;
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: flex-end;
  gap: 10px;
}

.logistics-sender > .summary > .sender-info > .info {
  display: flex;
  width: 800px;
  padding: 15px;
  flex-direction: column;
  border-radius: 10px;
  background: #f5f5f5;
  gap: 5px;
  margin: 10px 0px;
}

.logistics-sender > .summary > .sender-info > .info > p {
  color: #000;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.logistics-sender > .summary > .sum {
  display: flex;
  width: 820px;
  padding: 15px;
  align-items: center;
  flex-direction: column;
}

.logistics-sender > .summary > .sum > .in {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0px;
  border-bottom: 1px solid #284697;
  margin-bottom: 10px;
}

.logistics-sender > .summary > .sum > .in > p {
  color: #000;
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 500;
}

.logistics-sender > .summary > .sum > .sum-items {
  width: 800px;
  border-radius: 20px;
  border: 1px solid #e0e0e0;
  background: #fff;
}
.logistics-sender > .summary > .sum > .sum-items > .in {
  width: 100%;
  height: 65px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e0e0e0;
}

.logistics-sender > .summary > .sum > .sum-items > .in > p {
  padding-left: 15px;
  color: #202020;
  text-align: center;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 600;
}

.logistics-sender > .summary > .sum > .sum-items > .items {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
}

.logistics-sender > .summary > .sum > .sum-items > .items > .wrapper {
  width: 100%;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 100px;
  margin-top: 30px;
}

.logistics-sender > .summary > .sum > .sum-items > .items > .wrapper > span {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 15px;
}

.logistics-sender
  > .summary
  > .sum
  > .sum-items
  > .items
  > .wrapper
  > span
  > h3 {
  color: #202020;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}

.logistics-sender
  > .summary
  > .sum
  > .sum-items
  > .items
  > .wrapper
  > span
  > p {
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
}

.logistics-sender > .summary > .ship-cost {
  width: 800px;
  height: 211px;
  border-radius: 20px;
  border: 1px solid #e0e0e0;
  background: #fff;
}

.logistics-sender > .summary > .ship-cost > .in {
  width: 100%;
  height: 50px;
  padding-left: 15px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e0e0e0;
}

.logistics-sender > .summary > .ship-cost > .in > p {
  color: #202020;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 700;
}

.logistics-sender > .summary > .ship-cost > .cost {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  padding-left: 15px;
}

.logistics-sender > .summary > .ship-cost > .cost > p {
  color: #284697;
  font-family: Strike;
  font-size: 26px;
  font-weight: 700;
  line-height: 39px;
}

.submit {
  display: flex;
  width: 160px;
  height: 55px;
  padding: 10px 50px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 38px;
  background: #d0300e;
  color: #fff;
  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 700;
  line-height: 123.023%;
  border: none;
  cursor: pointer;
  margin: 50px;
}

.submit:disabled {
  cursor: not-allowed;
  background-color: #e95939;
}

.receipt-container {
  width: 81.9%;
  height: 100vh;
  position: fixed;
  top: 10%;
  right: 0%;
  display: flex;
  justify-content: center;
  background-color: #202020;
  flex-direction: column;
  align-items: center;
  padding: 150px 0px;
  overflow-y: scroll;
}

.receipt-container > .in {
  width: 100%;
  display: flex;
  margin: 20px 0px;
}

.receipt-container > .in > button {
  display: flex;
  width: 140px;
  height: 40px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  cursor: pointer;
}

.package-receipt-wrapper {
  width: 595px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  padding: 40px 30px;
  background: #fff;
}

.package-receipt-wrapper > h3 {
  color: #000;
  font-family: Montserrat;
  font-size: 9px;
  font-weight: 600;
  margin: 20px 0px;
}
.package-receipt-wrapper > p {
  color: #000;
  font-family: Montserrat;
  font-size: 9px;
  font-weight: 600;
  margin-bottom: 20px;
}

.logo-card {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo-box {
  width: 80px;
  height: 29px;
}

.logo-box > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.logo-card > h3 {
  color: #000;
  font-family: Montserrat;
  font-size: 8px;
  font-weight: 400;
}

.package-receipt-span {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  flex-direction: column;
}

.package-receipt-span > h3 {
  color: #000;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 800;
}

.package-receipt-span > img {
  width: 182px;
  height: 40px;
  object-fit: contain;
}

.package-receipt-info {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-top: 1px solid rgba(32, 32, 32, 0.5);
  border-bottom: 1px solid rgba(32, 32, 32, 0.5);
  padding: 20px 0px;
  gap: 95px;
  margin: 30px 0px;
}

.package-recipt-con-1 {
  width: 40%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 50px;
}

.package-recipt-con-1 > span {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.package-recipt-con-1 > span > h3 {
  color: #000;
  font-family: Montserrat;
  font-size: 8px;
  font-weight: 600;
}

.package-recipt-con-1 > span > p {
  color: #000;
  font-family: Montserrat;
  font-size: 6px;
  font-weight: 400;
}

.package-recipt-con-2 {
  width: 40%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 50px;
}

.package-recipt-con-2 > span {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.package-recipt-con-2 > span > h3 {
  color: #000;
  font-family: Montserrat;
  font-size: 8px;
  font-weight: 600;
}

.package-recipt-con-2 > span > p {
  color: #000;
  font-family: Montserrat;
  font-size: 6px;
  font-weight: 400;
}

.package-recipt-con-3 {
  width: 20%;
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.package-recipt-con-3 > span {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.package-recipt-con-3 > span > h3 {
  color: #000;
  font-family: Montserrat;
  font-size: 8px;
  font-weight: 600;
}

.package-recipt-con-3 > span > p {
  color: #000;
  font-family: Montserrat;
  font-size: 6px;
  font-weight: 400;
}

.package-trip-card {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 100px;
}

.package-trip-span-1 {
  width: 100%;
  height: 100%;
  display: flex;
  gap: 50px;
  align-items: flex-end;
  justify-content: flex-end;
}

.package-trip-span-1 > h3 {
  color: #000;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 700;
}

.package-trip-span-2 {
  width: 35%;
  display: flex;
  gap: 20px;
}

.package-trip-span-2 > span > p {
  color: #000;
  font-family: Montserrat;
  font-size: 8px;
  font-weight: 400;
}
.package-trip-span-2 > span > h3 {
  color: #000;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 700;
}

.package-info-wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin-top: 30px;
}

.package-info-wrapper-1 {
  width: 100%;
  display: flex;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(32, 32, 32, 0.6);
}

.package-info-wrapper-1 > h3 {
  width: 76.42px;
  color: #000;
  text-align: center;
  font-family: Montserrat;
  font-size: 9px;
  font-weight: 600;
}

.package-info-wrapper-2 {
  width: 100%;
  display: flex;
  padding: 10px 0px;
  border-bottom: 1px solid rgba(32, 32, 32, 0.6);
}

.package-info-wrapper-2 > h4 {
  width: 76.42px;
  color: #000;
  text-align: center;
  font-family: Montserrat;
  font-size: 7px;
  font-weight: 700;
}
.package-info-wrapper-2 > p {
  width: 76.42px;
  color: #000;
  text-align: center;
  font-family: Montserrat;
  font-size: 7px;
  font-weight: 400;
}

.package-support {
  width: 100%;
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 10px;
  margin: 20px 0px;
  border-bottom: 1px solid rgba(32, 32, 32, 0.5);
}

.package-support > p {
  color: #000;
  font-family: Montserrat;
  font-size: 6px;
  font-weight: 400;
}

.package-condition {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
}

.package-condition > h3 {
  color: #000;
  font-family: Montserrat;
  font-size: 8px;
  font-weight: 700;
}

.package-condition > span {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 5px;
}

.package-condition > span > p {
  color: #000;
  font-family: Montserrat;
  font-size: 5px;
  font-weight: 400;
}

/* Bulk package crewation css */

.logistic-bulk-packages-container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  gap: 20px;
  padding: 100px 20px 20px 276px;
}

.logistic-bulk-packages-container > .header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.logistic-bulk-packages-container > .header > h2 {
  font-family: Strike;
  color: #202020;
  font-size: 18px;
}

.logistic-bulk-packages-container > .header > button {
  width: 100px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #284697;
  color: #fff;
  font-family: Montserrat;
  border: none;
  cursor: pointer;
  border-radius: 30px;
  font-weight: 600;
}
