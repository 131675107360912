* {
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
}

@font-face {
  font-family: Strike;
  src: url("../../../public/ProtestStrike-Regular.ttf");
}

@font-face {
  font-family: Montserrat;
  src: url("../../../public/Montserrat-VariableFont_wght.ttf");
}

.super-admin-dispatch-details-container {
  padding: 100px 0px 20px 260px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.super-admin-dispatch-details-container > .header {
  width: 100%;
  display: flex;
  padding: 20px;
  gap: 10px;
  border-bottom: 1px solid rgba(32, 32, 32, 0.4);
}

.super-admin-dispatch-details-container > .header > .icon {
  cursor: pointer;
}

.super-admin-dispatch-details-container > .header > p {
  color: #284697;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 700;
  line-height: 83.523%;
}

.super-admin-dispatch-details-container > .dispatch-card {
  width: 98%;
  padding-bottom: 1px;
  align-items: center;
  gap: 27px;
  border-radius: 10px;
  border: 1px solid #e0e0e0;
  background: #fff;
  margin-top: 20px;
}

.super-admin-dispatch-details-container > .dispatch-card > .header {
  display: flex;
  height: 66px;
  padding: 20px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e0e0e0;
}

.super-admin-dispatch-details-container > .dispatch-card > .header > p{
    color: #202020;
font-family: Montserrat;
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: normal;
}

.super-admin-dispatch-details-container > .dispatch-card > .sender {
  display: flex;
  width: 100%;
  height: 40px;
  align-items: center;
  gap: 10px;
  border-bottom: 1px solid #e0e0e0;
  padding: 20px;
}

.super-admin-dispatch-details-container > .dispatch-card > .sender > p {
  color: rgba(32, 32, 32, 0.6);
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.super-admin-dispatch-details-container > .dispatch-card > .sender-card {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 3fr);
  gap: 20px;
  padding: 20px;
}

.super-admin-dispatch-details-container
  > .dispatch-card
  > .sender-card
  > .info {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.super-admin-dispatch-details-container
  > .dispatch-card
  > .sender-card
  > .info
  > p {
  color: rgba(32, 32, 32, 0.8);
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
}
.super-admin-dispatch-details-container
  > .dispatch-card
  > .sender-card
  > .info
  > span {
  color: rgba(32, 32, 32, 0.8);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
