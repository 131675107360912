* {
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
}

@font-face {
  font-family: Montserrat;
  src: url("../../../public/Montserrat-VariableFont_wght.ttf");
}


.pending-orders-container {
  padding: 100px 20px 20px 276px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
}


.pending-orders-container > .header {
  display: flex;
  width: 100%;
  height: 100px;
  padding: 20px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(32, 32, 32, 0.4);
}

.pending-orders-container > .header > p {
  color: #284697;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 700;
  line-height: 83.523%;
}




.pending-orders-container > .header > span {
  display: flex;
  width: 310px;
  height: 44px;
  padding: 20px;
  align-items: center;
  gap: 10px;
  border-radius: 30px;
  border: 1px solid rgba(32, 32, 32, 0.6);
}

.pending-orders-container > .header > span > input {
  width: 100%;
  font-family: Montserrat;
  font-size: 12px;
  border: none;
  outline: none;
}


.pending-orders-container  > .pending-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pending-orders-container  > .pending-container > table {
  width: 100%;
  border: none;
}

.pending-orders-container  > .pending-container > table > thead > tr > th {
  color: #202020;
  font-size: 12px;
  font-weight: 600;
  text-align: start;
  font-family: Montserrat;
  padding: 10px 3px;
}
.pending-orders-container  > .pending-container > table > tbody > tr > td {
  color: #202020;
  font-size: 12px;
  font-weight: 400;
  font-family: Montserrat;
  text-align: start;
  padding: 10px 3px;
}


@media screen and (max-width:896px) {
  .pending-orders-container {
    padding: 80px 10px 10px 10px;
  }

  .pending-orders-container > .header {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 20px;
    padding: 0px;
  }

  .pending-orders-container  > .pending-container {
    min-width: 80%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    overflow-x: scroll;
  }
}

