* {
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
}

@font-face {
  font-family: Montserrat;
  src: url("../../../public/Montserrat-VariableFont_wght.ttf");
}
@font-face {
  font-family: Strike;
  src: url("../../../public/ProtestStrike-Regular.ttf");
}

.business-wallet-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
  padding: 100px 20px 20px 276px;
  /* background-color: red; */
}

.business-wallet-container > .header {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
  border-radius: 10px;
  background: rgba(208, 72, 14, 0.07);
  padding: 20px;
}

.business-wallet-container > .header > p {
  color: #000;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
}

.business-wallet-container > .header > span {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.business-wallet-container > .header > span > h3 {
  color: #000;
  font-family: Strike;
  font-size: 16px;
  font-weight: 600;
}

.business-wallet-container > .header > span > button {
  color: #fff;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 500;
  display: flex;
  padding: 8px 10px;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  background: #d0480e;
  border: none;
  cursor: pointer;
}



.business-wallet-container > h3 {
  color: #000;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 500;
}

.business-history-table {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
  border: 1px solid rgba(0, 0, 0, 0.1); 
  background: #fff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05); 
}


.business-history-table > table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0; 
}

.business-history-table > table > thead > tr {
  background-color: #f5f5f5; 
}

.business-history-table > table > thead > tr > th {
  color: #202020;
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  font-family: Montserrat;
  padding: 12px;
  border-bottom: 2px solid #ddd; 
}

.business-history-table > table > tbody > tr {
  transition: background-color 0.3s ease; 
}

.business-history-table > table > tbody > tr:nth-child(even) {
  background-color: #f9f9f9; 
}

.business-history-table > table > tbody > tr:hover {
  background-color: rgba(208, 72, 14, 0.1); 
}

.business-history-table > table > tbody > tr > td {
  color: #333;
  font-size: 14px;
  font-weight: 400;
  font-family: Montserrat;
  padding: 12px;
  border-bottom: 1px solid #ddd;
  text-align: left;
}




@media screen and (max-width: 896px) {
  .business-history-table {
    overflow-x: auto;
  }

  .business-history-table > table > thead > tr > th,
  .business-history-table > table > tbody > tr > td {
    font-size: 12px;
  }
}


.fund-wallet-overlay {
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  background: rgba(18, 18, 18, 0.40);
  position: fixed;
  top: 0%;
  right: 0%;
  z-index: 101;
}

.fund-wallet-overlay > .card {
  width: 663px;
  display: flex;
  padding: 25px 50px;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  border-radius: 10px;
  background: #fff;
}

.fund-wallet-overlay > .card > span {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.fund-wallet-overlay > .card > span > h3 {
color: #212121;
font-family: Montserrat;
font-size: 24px;
font-weight: 600;
line-height: 39px; 
}

.fund-wallet-overlay > .card > span > button {
background: transparent;
border: none;
cursor: pointer;
}

.fund-wallet-overlay > .card > form {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 15px;
}


.fund-wallet-overlay > .card > form > span {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 5px;
}

.fund-wallet-overlay > .card > form > span > label {
color: #202020;
font-family: Montserrat;
font-size: 14px;
font-weight: 400;
}
.fund-wallet-overlay > .card > form > span > input {
  width: 100%;
  height: 50px;
color: #202020;
font-family: Montserrat;
font-size: 14px;
font-weight: 400;
border-radius: 5px;
border: 1px solid rgba(33, 33, 33, 0.70);
background: #FFF;
padding-left: 10px;
}

.fund-wallet-overlay > .card > form > button {
  display: flex;
width: 160px;
height: 55px;
padding: 10px 20px;
justify-content: center;
align-items: center;
flex-shrink: 0;
border-radius: 38px;
background:  #D0300E;
cursor: pointer;
border: none;
color: #fff;
font-family: Montserrat;  
}

.fund-wallet-overlay > .card > form > button:disabled{
  cursor: progress;
  background: rgb(151, 151, 151);
  color: #202020;
}

@media screen and (max-width:896px) {
  .business-wallet-container {
    padding: 100px 10px 10px 10px;
  }

  .business-history-table {
    overflow-x: scroll;
    padding: 10px;
  }

  .fund-wallet-overlay {
    padding: 10px;
    /* display: flex;
    justify-content: flex-start;
    align-items: flex-start; */
  }

  .fund-wallet-overlay > .card {
    padding: 20px;
  }

}




