* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: Strike;
  src: url("../../../public/ProtestStrike-Regular.ttf");
}

@font-face {
  font-family: Montserrat;
  src: url("../../../public/Montserrat-VariableFont_wght.ttf");
}

.business-create-concerns {
  padding: 100px 20px 20px 270px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  background: #f9f9f9;
}

.business-create-concerns > span {
  width: 32%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
}

.business-create-concerns > span > h2 {
  font-family: Strike;
  font-weight: 400;
  color: #d0310e;
  margin: 10px 0px;
}

.business-create-concerns > form {
  width: 400px;
  border-radius: 20px;
  padding: 20px;
  border: 0.6px solid rgba(0, 0, 0, 0.8);
  background: var(
    --bg,
    linear-gradient(
      358deg,
      rgba(255, 255, 255, 0) -10.62%,
      rgba(255, 205, 0, 0.08) 115.73%
    )
  );
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.business-create-concerns > form > div {
  width: 100%;
  display: flex;
  margin-bottom: 10px;
  flex-direction: column;
  gap: 5px;
  font-family: Montserrat;
}

.business-create-concerns > form > label {
  display: block;
  font-family: Montserrat;
  font-size: 14px;
  margin-bottom: 5px;
}

input,
select,
textarea {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-family: Montserrat;
  background: #fff;
  outline: none;
}

.business-create-concerns > form > textarea {
  height: 100px;
  resize: none;
}

.business-create-concerns > form > div > .tracking-status {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}

.business-create-concerns > form > div > .tracking-status > p {
  font-family: Montserrat;
  font-weight: 400;
}
.business-create-concerns > form > div > .tracking-status > h3 {
  font-family: Montserrat;
  font-weight: 600;
  font-size: 14px;
}

.business-create-concerns > form > button {
  width: 100%;
  padding: 10px;
  background: #d0310e;
  color: white;
  border: none;
  font-family: Strike;
  font-size: 16px;
  cursor: pointer;
  transition: 0.3s;
  border-radius: 5px;
}

.business-create-concerns > form > button:disabled {
  background: #ccc;
  cursor: not-allowed;
}

.business-create-concerns > form > .input-container > .tracking-messages {
  font-size: 14px;
  font-weight: 600;
  font-family: Montserrat;
  padding: 8px;
  border-radius: 5px;
}

.business-create-concerns > form > .input-container > .tracking-messages.valid {
  color: green;
  background-color: #e6f4ea;
  border: 1px solid green;
}

.business-create-concerns
  > form
  > .input-container
  > .tracking-messages.errors {
  color: #d0310e;
  background-color: #fde8e8;
  border: 1px solid red;
}

@media screen and (max-width: 896px) {
  .business-create-concerns {
    padding: 70px 10px 20px 10px;
  }

  .business-create-concerns > span {
    width: 100%;
  }

  .business-create-concerns > form {
    width: 100%;
    padding: 10px;
  }
}
