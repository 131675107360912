* {
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
}

@font-face {
  font-family: Montserrat;
  src: url("../../../public/Montserrat-VariableFont_wght.ttf");
}

@font-face {
  font-family: Strike;
  src: url("../../../public/ProtestStrike-Regular.ttf");
}

.bulk-packages-container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  gap: 20px;
  padding: 100px 20px 20px 276px;
}

.bulk-packages-container>.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.bulk-packages-container>.header>h2 {
  font-family: Strike;
  color: #202020;
  font-size: 18px;
}

.bulk-packages-container>.header>button {
  padding: 2px 10px;
}

.send-overlay,
.error-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(29, 29, 29, 0.7);
  color: white;
  z-index: 101;
}