* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: Strike;
  src: url("../../../public/ProtestStrike-Regular.ttf");
}

@font-face {
  font-family: Montserrat;
  src: url("../../../public/Montserrat-VariableFont_wght.ttf");
}

.my-concerns-container {
  padding: 100px 20px 20px 270px;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #f9f9f9;
  font-family: Montserrat;
  /* background-color: green; */
}

.my-concerns-container .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  padding: 15px 20px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  width: 100%;
  margin-bottom: 20px;
}

.my-concerns-container .header > h2 {
  font-family: Strike;
  font-size: 22px;
  color: #333;
}

.my-concerns-container .header > .wrapper {
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: center;
  gap: 15px;
  /* background-color: green; */
}

.my-concerns-container > .header > .wrapper > input {
  width: 280px;
  height: 100%;
  padding-left: 10px;
  font-size: 14px;
  outline: none;
  transition: 0.3s;
  border-radius: 30px;
  border: 1px solid #ddd;
}

.my-concerns-container > .header > .wrapper > input:focus {
  border-color: #284697;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.3);
}

.my-concerns-container .header button {
  background: #d0310e;
  color: white;
  border: none;
  padding: 10px 15px;
  font-size: 14px;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;
}

.my-concerns-container h2 {
  font-family: Strike;
  margin-bottom: 20px;
}

.my-concerns-container .loading,
.my-concerns-container .error,
.my-concerns-container .no-concerns {
  text-align: center;
  font-size: 14px;
  padding: 10px;
  color: #666;
}

.my-concerns-container .error {
  color: #d32f2f;
}

.my-concerns-container .status {
  font-weight: bold;
  padding: 5px 10px;
  border-radius: 5px;
}

.my-concerns-container .status.open {
  background: #ffcd00;
  color: #fff;
  font-weight: 600;
}

.my-concerns-container .status.pending {
  background: #ffeb3b;
  color: #333;
}

.my-concerns-container .status.resolved {
  background: #4caf50;
  color: #fff;
}

.my-concerns-container .status.rejected {
  background: #f44336;
  color: #fff;
}

.my-concerns-container .view-response-btn {
  background: #284697;
  color: #fff;
  border: none;
  padding: 6px 12px;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;
  font-weight: 600;
}

.my-concerns-container .view-response-btn:hover {
  background: #0056b3;
}

.my-concerns-container .response-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fadeIn 0.3s ease-in-out;
  z-index: 1001;
}

.my-concerns-container .response-box {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  width: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 15px;
  animation: slideIn 0.3s ease-in-out;
}

.my-concerns-container .response-box > span {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 10px;
  /* background-color: red; */
}

.my-concerns-container .response-box > span > h3 {
  font-family: Strike;
  color: #284697;
}

.my-concerns-container .response-box > span > p {
  font-family: Montserrat;
  color: #202020;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.4rem;
  text-align: left;
}

.my-concerns-container .response-box > span > h4 {
  font-family: Strike;
  color: #d0310e;
  font-size: 18px;
}

.my-concerns-container .close-btn {
  background: #d32f2f;
  color: #fff;
  border: none;
  padding: 6px 12px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
}

.list-of-concerns {
  width: 100%;
  overflow: scroll;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideIn {
  from {
    transform: translateY(-20px);
  }
  to {
    transform: translateY(0);
  }
}

@media screen and (max-width: 896px) {
  .my-concerns-container {
    padding: 70px 10px 20px 10px;
  }

  .my-concerns-container > .header {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    padding: 10px 15px;
  }

  .my-concerns-container > .header > .wrapper {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
  }

  .my-concerns-container > .header > .wrapper > input {
    width: 100%;
    height: 40px;
  }
}
