* {
    padding: 0%;
    margin: 0%;
    box-sizing: border-box;
  }
  
  @font-face {
    font-family: Strike;
    src: url(/public/ProtestStrike-Regular.ttf);
  }
  
  @font-face {
    font-family: Montserrat;
    src: url(/public/Montserrat-VariableFont_wght.ttf);
  }
  
  @font-face {
    font-family: Montserrat Alternates;
    src: url(/public/MontserratAlternates-Regular.ttf);
  }

  
.business-sign-up {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding: 20px 0px;  
}

.business-sign-up > h3 {
  color: #1f4798;
  font-family: Montserrat;
  font-size: 26px;
  font-weight: 700;
  margin: 30px 0px;
}

.business-sign-up > form {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}

.business-sign-up > form > span {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.business-sign-up > form > span > label {
  /* color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400; */
  color: #1f4798;
  font-size: 14px;
  font-family: Strike;
  font-weight: 400;
}

.business-sign-up > form > span > input {
  width: 100%;
  height: 50px;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
  background: transparent;
  outline: none;
  margin: 5px 0px;
  padding-left: 10px;
  font-family: Montserrat;
}

.business-sign-up > form > .input-combination-1 {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.business-sign-up > form > .input-combination-1 > span > label {
  /* color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400; */
  color: #1f4798;
  font-size: 14px;
  font-family: Strike;
  font-weight: 400;
}

.business-sign-up > form > .input-combination-1 > span {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.business-sign-up > form > .input-combination-1 > span > input {
  width: 100%;
  height: 50px;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
  background: transparent;
  outline: none;
  margin: 5px 0px;
  padding-left: 10px;
  font-family: Montserrat;
}

.input-combination-2 > input {
  width: 100%;
  height: 100%;
  background: transparent;
  outline: none;
  margin: 5px 0px;
  padding-left: 10px;
  border: 1px solid #e0e0e0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.zip-code {
  width: 100px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border: 1px solid #e0e0e0;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.business-sign-up > form > button {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  margin: 5px 0px;
  border-radius: 80px;
  background: #d0300e;
  cursor: pointer;
  color: #fff;
  font-family: Montserrat Alternates;
  font-size: 16px;
  font-weight: 700;
}

.business-sign-up > form > button:disabled {
  /* background-color: #f36c4d; */
  cursor: not-allowed;
}

.business-sign-up > form > p {
  color: #202020;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
}

.business-sign-up > form > p > span {
  color:#D0310E;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  text-decoration-line: underline;
  cursor: pointer;
}

@media screen and (max-width:896px) {  
  .business-sign-up {
      padding: 10px;
  }
  
  }