* {
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
}

@font-face {
  font-family: Strike;
  src: url(/public/ProtestStrike-Regular.ttf);
}

@font-face {
  font-family: Montserrat;
  src: url(/public/Montserrat-VariableFont_wght.ttf);
}

.nav-container {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  top: 0%;
  z-index: 100;
  position: sticky;
  border-bottom: 1px solid #e0e0e0;
  padding: 0px 100px;
}

.nav-link {
  width: 50%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 40px;
}

.nav-link > a {
  text-decoration: none;
  font-size: 15px;
  font-weight: 400;
  line-height: 16px;
  font-family: Montserrat;
  color: #202020e5;
}

.logo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  margin-left: 12px;
}

.mobile-menu {
  display: none;
}

@media screen and (max-width: 896px) {
  .nav-link {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 80px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    transform: translateX(-100%);
    transition: 0.3s ease-in-out;
    background: #fbfaf3;
    padding: 10px;
  }

  .nav-link > a {
    color: #284697;
  }

  .nav-link.mobile.active {
    transform: translateX(0%);
    transition: 0.3s ease-in-out;
  }

  .mobile-menu {
    display: block;
    margin-right: 10px;
  }

  .nav-container {
    padding: 0px;
  }

  .logo {
    width: 100px;
  }
}

.service-card-box {
  width: 40%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 5px;
}

.service-card-box > h3 {
  font-size: 15px;
  font-weight: 400;
  line-height: 16px;
  font-family: Montserrat;
  color: #202020e5;
}

.mobile-service-drop {
  display: none;
}

@media screen and (max-width: 896px) {
  .service-card-box {
    display: none;
  }

  .mobile-service-drop {
    display: block;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;
    border-top: 0.5px solid rgba(32, 32, 32, 0.4);
    border-bottom: 0.5px solid rgba(32, 32, 32, 0.4);
    padding: 5px 0px;
  }

  .our-service {
    width: 100%;
  }

  .mobile-service-drop > span {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .mobile-service-drop > span > h3 {
    font-family: Strike;
    font-weight: 400;
    font-size: 24px;
    color: #284697;
  }

  .mobile-service-drop-card {
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 15px;
    max-height: 0;
    overflow: hidden;
    opacity: 0;
    transition: 0.5s ease-in-out, opacity 0.3s ease-in-out,
      padding 0.3s ease-in-out;
    padding: 0 10px;
  }

  .mobile-service-drop-card.open {
    background: var(
      --bg,
      linear-gradient(
        358deg,
        rgba(255, 255, 255, 0) -10.62%,
        rgba(255, 205, 0, 0.08) 115.73%
      )
    );
    max-height: 300px;
    opacity: 1;
    padding: 10px 0px;
  }

  .mobile-service-drop-card > a {
    text-decoration: none;
    color: #284697;
    font-family: Montserrat;
    font-size: 13px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
  }
}

.service-mother {
  width: 230px;
  top: 79.5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  position: absolute;
  padding: 10px;
  background: #fff;
  z-index: 100;
  left: 35%;
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  transition: max-height 0.3s ease-out, opacity 0.3s ease-out;
  border-bottom: 2px solid #ffcd00;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  box-shadow: 0px 10px 24px 0px rgba(0, 0, 0, 0.07);
}

.service-mother.open {
  max-height: 200px;
  opacity: 1;
}

.service-mother.closed {
  max-height: 0;
  opacity: 0;
}

.service-mother > a {
  width: 100%;
  text-decoration: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  font-family: Montserrat;
  font-size: 14px;
  color: #121212;
}
