* {
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
}

@font-face {
  font-family: Montserrat;
  src: url("../../../public/Montserrat-VariableFont_wght.ttf");
}

.logistics-inter-state {
  padding: 100px 20px 20px 276px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #f9f9f9;
}

.logistics-inter-state > .header {
  display: flex;
  width: 100%;
  height: 100px;
  padding: 20px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(32, 32, 32, 0.4);
  right: 0%;
  top: 80px;
  background-color: white;
}

.logistics-inter-state > .header > p {
  color: #284697;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 700;
  line-height: 83.523%;
}

.logistics-inter-state > .header > button {
  color: #fff;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  display: flex;
  padding: 10px 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 40px;
  background: #284697;
  border: none;
  cursor: pointer;
}

.logistics-inter-state > .header > button:disabled {
  background-color: #bcbcbc;
  cursor: not-allowed;
}

.logistics-inter-state > .dispatch-assigned {
  width: 100%;
  padding: 20px;
  display: flex;
  margin-top: 20px;
  gap: 20px;
}

.dispatch-assigned > .assigned {
  width: 360px;
  min-height: 570px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}

.dispatch-assigned > .assigned > span {
  width: 340px;
  display: flex;
  padding: 16px 20px;
  align-items: center;
  border-radius: 100px;
  border: 1px solid #bcbcbc;
  gap: 10px;
}

.dispatch-assigned > .assigned > span > input {
  color: #757575;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  border: none;
  outline: none;
  background: #f9f9f9;
  width: 100%;
}

.dispatch-assigned > .assigned > .card {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.dispatch-assigned > .assigned > .card > p {
  padding: 5px 20px;
  color: #000;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 700;
}

.dispatch-assigned > .assigned > .card > .package-item {
  width: 100%;
  display: flex;
  padding: 20px;
  align-items: flex-end;
  gap: 100px;
  border-bottom: 1px solid #e0e0e0;
}

.dispatch-assigned > .assigned > .card > .package-item .items {
  width: 200px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.dispatch-assigned > .assigned > .card > .package-item .items > h3 {
  color: #202020;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
}

.dispatch-assigned > .assigned > .card > .package-item > .items > p {
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
}

.dispatch-assigned > .assigned > .card > .package-item > button {
  display: flex;
  width: 80px;
  height: 38px;
  padding: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 30px;
  border: 0.5px solid #d0310e;
  background: #fff;
  color: #d0310e;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  cursor: pointer;
}

.dispatch-assigned > .driver-con {
  width: 360px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.dispatch-assigned > .driver-con > span {
  width: 340px;
  display: flex;
  padding: 16px 20px;
  align-items: center;
  border-radius: 100px;
  border: 1px solid #bcbcbc;
  gap: 10px;
}

.dispatch-assigned > .driver-con > span > input {
  color: #757575;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  border: none;
  outline: none;
  background: #f9f9f9;
  width: 100%;
}

.dispatch-assigned > .driver-con > .driver-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.dispatch-assigned > .driver-con > .driver-list .card {
  display: flex;
  width: 346px;
  padding: 20px;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
  border-radius: 10px;
  border: 1px solid #284697;
}

.driver-list > .card > .inner {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.driver-list > .card > .inner > h3 {
  color: #000;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 700;
}

.driver-list > .card > .inner > span {
  color: #000;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
}

.driver-list > .card > .inner > p {
  color: #d0300e;
  font-family: Montserrat;
  font-size: 16px;
  font-style: italic;
  font-weight: 400;
}

.driver-list > .card > button {
  display: flex;
  width: 100px;
  height: 38px;
  padding: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 30px;
  background: #d0310e;
  color: #fff;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  border: none;
  outline: none;
  cursor: pointer;
}

.interstate-assigned-over {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0%;
  right: 0%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(18, 18, 18, 0.5);
  z-index: 100;
}
.interstate-assigned-over > .card {
  display: flex;
  padding: 25px 50px;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  border-radius: 10px;
  background: #fff;
}

.interstate-assigned-over > .card > h3 {
  color: #202020;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 800;
}

.interstate-assigned-over > .card > p {
  color: #202020;
  text-align: center;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
  line-height: 118.243%;
}

.interstate-assigned-over > .card > .btn {
  display: flex;
  gap: 20px;
}

.interstate-assigned-over > .card > .btn > button:nth-child(1) {
  display: flex;
  width: 120px;
  height: 40px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  border: 1px solid rgba(32, 32, 32, 0.5);
  background: #fefefe;
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  cursor: pointer;
  font-weight: 700;
}

.interstate-assigned-over > .card > .btn > button:nth-child(2) {
  display: flex;
  width: 120px;
  height: 40px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  background: #189b48;
  color: #fff;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  border: none;
  cursor: pointer;
}
