* {
    padding: 0%;
    margin: 0%;
    box-sizing: border-box;
  }
  
  @font-face {
    font-family: Montserrat;
    src: url("../../../public/Montserrat-VariableFont_wght.ttf");
  }
  @font-face {
    font-family: Strike;
    src: url("../../../public/ProtestStrike-Regular.ttf");
  }

  .business-bulk-upload {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    padding: 100px 20px 20px 276px;
  }

  .business-bulk-upload > .header {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
  }

  .business-bulk-upload > .header > p {
    font-family: Strike;
    color: #202020;
    font-size: 18px;
  }

  .business-bulk-upload > .wrapper {
    width: 50%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 20px;
    border: 0.6px solid #E0E0E0;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    border-radius: 0px 10px 10px 0px;
    background: #FFF;
    box-shadow: 0px 9px 27px 0px rgba(0, 0, 0, 0.05);
  }

  .business-bulk-upload > .wrapper > span {
    width: 100%;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 50px;
    background: var(--bg, linear-gradient(358deg, rgba(255, 255, 255, 0.00) -10.62%, rgba(255, 205, 0, 0.08) 115.73%));
  }

  .business-bulk-upload > .wrapper > .file {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;
    padding: 20px 50px;
  }

  .business-bulk-upload > .wrapper > .file > h3 {
    font-family: Strike;
    color: #284697;
    font-size: 24px;
    font-weight: 400;
    margin-bottom: 50px;
  }

  
  .business-bulk-upload > .wrapper > .file > .upload-card {
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background: #F5F5F5;
    gap: 10px;
    cursor: pointer;
    color: #284697;
  }

  .business-bulk-upload > .wrapper > .file > p {
    color: #202020;
    font-family: Montserrat;
    font-weight: 400;
    font-size: 14px;
  }

.business-bulk-upload > .wrapper > .file > .upload-card > p {
  color: #284697;
  font-family: Montserrat;
  font-size: 16px;
}

.business-bulk-upload > .wrapper > .file > .info {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  border: 0.6px solid #E0E0E0;
  padding: 20px 10px;
  border-radius: 10px;
  flex-direction: column;
}
.business-bulk-upload > .wrapper > .file > .info > span {
width: 100%;
display: flex;
justify-content: space-between;
align-items: flex-start;
}

.business-bulk-upload > .wrapper > .file > .info > span > p {
  font-family: Montserrat;
  font-weight: 400;
  
}
.business-bulk-upload > .wrapper > .file > .info > span > button {
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  padding: 2px;
  border:0.6px solid #E0E0E0;
  font-size: 16px;
  cursor: pointer;
}

.business-bulk-upload > .wrapper > .file > .info > .uploading-range {
  width: 100%;
  height: 7px;
  background: #284697;
  border-radius: 5px;
  margin-top: 15px;
}


.business-bulk-upload > .wrapper > .file > .info > .success {
  width: 100%;
  display: flex;
  justify-content: space-between;
}


.business-bulk-upload > .wrapper > .file > .info > .success > h4 {
  font-family: Montserrat;
  font-weight: 400;
  font-size: 12px;
}


.business-bulk-upload > .wrapper > .file > button {
  background: grey;
  border: none;
  cursor: pointer;
  padding: 10px 30px;
  color: white;
  border-radius: 5px;
  font-family: Montserrat;
  margin-top: 30px;
}


@media screen and (max-width:896px) {
  .business-bulk-upload {
    padding: 80px 10px 10px 10px;
  }

  
  .business-bulk-upload > .wrapper {
    width: 100%;
  }

  .business-bulk-upload > .wrapper > span {
    padding: 10px;
  }

  .business-bulk-upload > .wrapper > .file {
    padding: 10px;
  }

  
  .business-bulk-upload > .wrapper > .file > h3 {
    font-size: 18px;
  }
}




  