* {
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
}

@font-face {
  font-family: Montserrat;
  src: url("../../../public/Montserrat-VariableFont_wght.ttf");
}
@font-face {
  font-family: Strike;
  src: url("../../../public/ProtestStrike-Regular.ttf");
}

.business-package-receipt {
  padding: 100px 20px 20px 276px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.business-package-receipt > .header {
  width: 100%;
  padding: 0px 0px 20px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(32, 32, 32, 0.4);
}

.business-package-receipt > .header > p {
  color: #284697;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 700;
  line-height: 83.523%;
}

.business-package-receipt > .header > .in {
  display: flex;
  align-items: center;
  justify-content: center;
}

.business-package-receipt > .header > .in > span {
  display: flex;
  gap: 10px;
  width: 280px;
  height: 44px;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  border: 1px solid rgba(32, 32, 32, 0.6);
  padding: 10px;
}

.business-package-receipt > .header > .in > span > input {
  width: 100%;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  background: transparent;
  font-family: Montserrat;
}

.business-package-receipt > .header > .in > .btn {
  display: flex;
  gap: 10px;
}

.business-package-receipt > .header > .in > button {
  height: 43px;
  padding: 10px 20px;
  gap: 10px;
  border-radius: 66px;
  background: #284697;
  color: #fff;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  outline: none;
  border: none;
}

.business-package-receipt > .package-receipt-table {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 10px;
}

.business-package-receipt > .package-receipt-table > .delete-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.business-package-receipt > .package-receipt-table > .delete-header > p {
  font-family: Montserrat;
  color: #202020;
  font-weight: 400;
}

.business-package-receipt > .package-receipt-table > .selected-receipt {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 10px 0px;
}

.business-package-receipt > .package-receipt-table > .selected-receipt > p {
  font-family: Montserrat;
  color: #d0310e;
  font-weight: 400;
}
.business-package-receipt
  > .package-receipt-table
  > .selected-receipt
  > .delete-icon {
  cursor: pointer;
  color: #d0310e;
}

.business-package-receipt > .package-receipt-table > table {
  width: 100%;
}

.business-package-receipt > .package-receipt-table > table > thead > tr > th {
  color: #202020;
  font-size: 12px;
  font-weight: 600;
  text-align: start;
  font-family: Montserrat;
  padding: 10px 3px;
}

.business-package-receipt > .package-receipt-table > table > tbody > tr > td {
  color: #202020;
  font-size: 12px;
  font-weight: 400;
  font-family: Montserrat;
  text-align: start;
  padding: 10px 3px;
}

.business-package-receipt
  > .package-receipt-table
  > table
  > tbody
  > tr
  > td
  > .action {
  display: flex;
  align-items: center;
  gap: 20px;
}

.business-package-receipt
  > .package-receipt-table
  > table
  > tbody
  > tr
  > td
  > .action
  > svg {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.business-receipt-delete-overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  right: 0%;
  top: 0%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(32, 32, 32, 0.7);
  z-index: 101;
}

.business-receipt-delete-overlay > .card {
  display: flex;
  padding: 25px 50px;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  border-radius: 10px;
  background: #fff;
}

.business-receipt-delete-overlay > .card > h3 {
  color: #202020;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 800;
}

.business-receipt-delete-overlay > .card > p {
  width: 70%;
  color: #202020;
  text-align: center;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.2rem;
}

.business-receipt-delete-overlay > .card > .btn {
  display: flex;
  align-items: center;
  gap: 15px;
}

.business-receipt-delete-overlay > .card > .btn > button:nth-child(1) {
  display: flex;
  width: 120px;
  height: 40px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  border: 1px solid rgba(32, 32, 32, 0.5);
  background: #fefefe;
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
}
.business-receipt-delete-overlay > .card > .btn > button:nth-child(2) {
  display: flex;
  width: 120px;
  height: 40px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #fff;
  font-family: Montserrat;
  font-size: 14px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  background: #d0310e;
  font-weight: 700;
}

@media screen and (max-width: 896px) {
  .business-package-receipt {
    padding: 80px 10px 10px 10px;
  }

  .business-package-receipt > .header {
    flex-direction: column;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 15px;
  }

  .business-package-receipt > .header > .in {
    width: 100%;
  }

  .business-package-receipt > .header > .in > span {
    width: 100%;
  }

  .business-package-receipt > .header > p {
    font-size: 18px;
  }

  .business-package-receipt > .package-receipt-table > table {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    overflow-x: scroll;
  }

  .business-package-receipt > .package-receipt-table > table > thead {
    width: 100%;
  }

  .business-receipt-delete-overlay {
    padding: 10px;
  }

  .business-receipt-delete-overlay > .card > p {
    width: 90%;
  }
}
