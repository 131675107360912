* {
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
}

@font-face {
  font-family: Montserrat;
  src: url("../../../public/Montserrat-VariableFont_wght.ttf");
}
@font-face {
  font-family: Strike;
  src: url("../../../public/ProtestStrike-Regular.ttf");
}
.business-package-creation {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  padding: 100px 20px 20px 276px;
}

.business-package-creation > .header {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.business-package-creation > .header > span {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
}

.business-package-creation > .header > span > p {
  color: #212121;
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 600;
}

.business-package-creation > .ship-form {
  width: 80%;
  align-items: center;
  border-radius: 20px;
  padding: 20px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 20px;
  border: 0.8px solid rgba(0, 0, 0, 0.7);
  background: #fff;
}

.business-package-creation > .ship-form > form {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 30px;
  padding: 30px 0px;
}

.business-package-creation > .ship-form > form > p {
  color: #000;
  text-align: center;
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 500;
}

.business-package-creation > .ship-form > form > .inner {
  display: grid;
  grid-template-columns: repeat(2, 2fr);
  gap: 20px 50px;
}

.business-package-creation > .ship-form > form > .inner > label {
  display: flex;
  flex-direction: column;
  display: flex;
  width: 385px;
  border-radius: 5px;
  background: #fff;
  gap: 8px;
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
}

.business-package-creation > .ship-form > form > .inner > label > input {
  width: 100%;
  padding-left: 20px;
  height: 50px;
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
  background: #fff;
  outline: none;
}
.business-package-creation > .ship-form > form > .inner > label > select {
  width: 100%;
  padding-left: 20px;
  height: 50px;
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
  background: #fff;
  outline: none;
}

.business-package-creation > .ship-form > form > .inner > label > textarea {
  display: flex;
  width: 100%;
  height: 70px;
  padding: 20px;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
  background: #fff;
  outline: none;
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
}
.business-package-creation > .ship-form > form > .inner > label > .num {
  display: flex;
  align-items: center;
}

.business-package-creation
  > .ship-form
  > form
  > .inner
  > label
  > .num
  > select {
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
  border: 1px solid #e0e0e0;
  background: #fff;
  height: 50px;
  outline: none;
}

.business-package-creation > .ship-form > form > .inner > label > .num > input {
  width: 100%;
  padding-left: 20px;
  height: 50px;
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  border: 1px solid #e0e0e0;
  background: #fff;
  outline: none;
}

.business-package-creation > .ship-form > form > .inner > label > select {
  width: 100%;
  padding-left: 20px;
  height: 50px;
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  border: 1px solid #e0e0e0;
  background: #fff;
  outline: none;
}

.business-package-creation > .ship-form > form > button {
  width: 160px;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
  font-size: 24px;
  cursor: pointer;
  border: none;
  background: #d0300e;
  border-radius: 30px;
  font-weight: 700;
  font-size: 16px;
  font-family: Montserrat;
}

@media screen and (max-width: 896px) {
  .business-package-creation {
    padding: 70px 10px 20px 10px;
  }

  .business-package-creation > .ship-form {
    width: 100%;
    padding: 10px;
  }

  .business-package-creation > .ship-form > form {
    width: 100%;
  }

  .business-package-creation > .ship-form > form > p {
    font-size: 16px;
  }

  .business-package-creation > .ship-form > svg {
    width: 100%;
  }

  .business-package-creation > .ship-form > form > .inner {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 15px;
  }

  .business-package-creation > .ship-form > form > .inner > label {
    width: 100%;
  }
}

/* business-receiver css  */

.business-receiver-container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding: 100px 20px 20px 276px;
}

.business-receiver-container > .header {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.business-receiver-container > .header > h3 {
  font-family: Montserrat;
  color: #202020;
  font-weight: 600;
  font-size: 20px;
}

.business-receiver-container > .receiver-shipping {
  width: 80%;
  align-items: center;
  border-radius: 20px;
  padding: 20px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 20px;
  border: 0.8px solid rgba(0, 0, 0, 0.7);
  background: #fff;
}

.business-receiver-container > .receiver-shipping > .card {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 20px;
  padding-left: 145px;
}

.business-receiver-container > .receiver-shipping > .card > p {
  color: #202020;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
}

.business-receiver-container > .receiver-shipping > P {
  color: #202020;
  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  line-height: 130%;
}

.business-receiver-container > .receiver-shipping > .wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin: 30px 0px;
}

.business-receiver-container > .receiver-shipping > .wrapper > p {
  font-family: Montserrat;
  font-weight: 400;
  font-size: 18px;
  margin: 10px 0px;
}

.business-receiver-container
  > .receiver-shipping
  > .wrapper
  > .business-package-info {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 15px;
  border-radius: 10px;
  background: #f5f5f5;
  padding: 15px;
}

.business-receiver-container
  > .receiver-shipping
  > .wrapper
  > .business-package-info
  > p {
  font-family: Montserrat;
  font-weight: 400;
  font-size: 14px;
  color: #202020;
}

.business-receiver-container > .receiver-shipping > .wrapper > .return-box {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.business-receiver-container > .receiver-shipping > .wrapper > .return-box > a {
  text-decoration: none;
  margin: 5px 0px 30px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  color: #d0300e;
  font-family: Montserrat;
  font-size: 12px;
}

.business-receiver-container > .receiver-shipping > span {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: 15px 0px;
}

.business-receiver-container > .receiver-shipping > span > p {
  color: #202020;
  text-align: center;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 600;
}

.business-receiver-container > .receiver-shipping > form {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: 15px;
}

.shipping-form-container > span {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.business-receiver-container
  > .receiver-shipping
  > form
  > .shipping-combination {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 50px;
}

.business-receiver-container
  > .receiver-shipping
  > form
  > .shipping-combination
  > span {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.business-receiver-container
  > .receiver-shipping
  > form
  > .shipping-combination
  > span
  > select {
  width: 100%;
  height: 50px;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
  background: #fff;
  padding-left: 10px;
  outline: none;
  font-family: Montserrat;
  font-size: 13px;
  font-weight: 400;
  color: #202020;
}

.business-receiver-container
  > .receiver-shipping
  > form
  > .shipping-combination
  > span
  > label {
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
}

.business-receiver-container
  > .receiver-shipping
  > form
  > .shipping-combination
  > span
  > input {
  width: 100%;
  height: 50px;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
  background: #fff;
  padding-left: 10px;
  outline: none;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
}

.business-receiver-container
  > .receiver-shipping
  > form
  > .shipping-combination-address {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.business-receiver-container
  > .receiver-shipping
  > form
  > .shipping-combination-address
  > span {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.business-receiver-container
  > .receiver-shipping
  > form
  > .shipping-combination-address
  > span
  > label {
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
}

.business-receiver-container
  > .receiver-shipping
  > form
  > .shipping-combination-address
  > span
  > select {
  height: 50px;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
  background: #fff;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  padding: 10px;
  color: #202020;
  outline: none;
}

.business-receiver-container > .receiver-shipping > form > button {
  width: 160px;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  cursor: pointer;
  background-color: #d0300e;
  border-radius: 38px;
  color: #fff;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 700;
  margin-top: 20px;
}

@media screen and (max-width: 896px) {
  .business-receiver-container {
    padding: 70px 10px 10px 10px;
  }

  .business-receiver-container > .receiver-shipping > svg {
    width: 100%;
  }
  .business-receiver-container > .receiver-shipping {
    width: 100%;
    padding: 10px;
  }

  .business-receiver-container > .receiver-shipping > span > p {
    font-size: 16px;
  }

  .business-receiver-container > .receiver-shipping > .wrapper {
    margin: 10px 0px;
  }

  .business-receiver-container
    > .receiver-shipping
    > .wrapper
    > .return-box
    > a {
    margin: 5px 0px 20px 0px;
  }

  .business-receiver-container
    > .receiver-shipping
    > form
    > .shipping-combination {
    gap: 15px;
  }
}

/* business-package-category css */

.business-package-category {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  padding: 100px 10px 20px 276px;
}

.business-package-category > .header {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.business-package-category > .header > h3 {
  font-family: Montserrat;
  color: #202020;
  font-weight: 600;
  font-size: 20px;
}

.business-package-category > .item {
  width: 80%;
  align-items: center;
  border-radius: 20px;
  padding: 20px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 20px;
  border: 0.8px solid rgba(0, 0, 0, 0.7);
  background: #fff;
  gap: 20px;
}

.business-package-category > .item > .wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.business-package-category > .item > .wrapper > p {
  font-family: Strike;
  font-weight: 400;
  font-size: 16px;
  margin: 7px 0px;
}

.business-package-category > .item > .wrapper > .business-package-info {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 15px;
  border-radius: 10px;
  background: #f5f5f5;
  padding: 15px;
  margin-bottom: 5px;
}

.business-package-category > .item > .wrapper > .business-package-info > p {
  font-family: Montserrat;
  font-weight: 400;
  font-size: 14px;
  color: #202020;
}

.business-package-category > .item > .wrapper > .return-box {
  width: 100%;
  height: 0px;
  display: flex;
  justify-content: flex-end;
}

.business-package-category > .item > .wrapper > .return-box > a {
  text-decoration: none;
  margin: 5px 0px 30px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  color: #d0300e;
  font-family: Montserrat;
  font-size: 12px;
}

.business-package-category > .item > form {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: 15px;
}

.business-package-category > .item > form > p {
  font-size: 18px;
  font-family: Montserrat;
  font-weight: 600;
  color: #202020;
}

.business-package-category > .item > form > .inner {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

.business-package-category > .item > form > .inner > label {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 5px;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
}

.business-package-category > .item > form > .inner > label > input {
  width: 100%;
  height: 50px;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
  background: #fff;
  padding-left: 10px;
  outline: none;
}
.business-package-category > .item > form > .inner > label > select {
  width: 100%;
  height: 50px;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
  background: #fff;
  padding: 10px;
  outline: none;
}

.business-package-category > .item > form > .btn {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
}

.business-package-category > .item > form > .btn button:nth-child(1) {
  width: 160px;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 38px;
  background: #fff;
  color: #202020;
  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 700;
  line-height: 123.023%;
  cursor: pointer;
  border: 1px solid #d0300e;
}
.business-package-category > .item > form > .btn button:nth-child(2) {
  width: 160px;
  height: 55px;
  background: #d0300e;
  color: #fff;
  text-align: center;
  border-radius: 38px;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 700;
  line-height: 123.023%;
  cursor: pointer;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.business-package-category > .item > form > .inner > .remove-item > button {
  width: 60px;
  height: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #d0300e;
  text-align: center;
  font-size: 24px;
  cursor: pointer;
  border: none;
  background: transparent;
}

@media screen and (max-width: 896px) {
  .business-package-category {
    padding: 70px 10px 10px 10px;
  }

  .business-package-category > .item {
    width: 100%;
    padding: 10px;
  }

  .business-package-category > .item > svg {
    width: 100%;
  }

  .business-package-category > .item > form > .inner {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .business-package-category > .item > form > p {
    margin-top: 20px;
  }

  .business-package-category > .item > form > .inner > .remove-item {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .business-package-category > .item > form > .inner > .remove-item > button {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    font-size: 20px;
  }

  .business-package-category > .item > form > .btn button:nth-child(1) {
    width: 100px;
    height: 40px;
    font-size: 14px;
  }
  .business-package-category > .item > form > .btn button:nth-child(2) {
    width: 100px;
    height: 40px;
    font-size: 14px;
  }
}

/* business-summary css */

.business-summary-detail {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
  padding: 100px 20px 20px 276px;
}

.business-summary-detail > h3 {
  font-family: Montserrat;
  color: #202020;
  font-weight: 600;
  font-size: 20px;
}

.business-summary-detail > .summary-details-combination {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items:flex-start;
  gap: 10px;
}

.business-summary-detail > .summary-details-combination > .card-1 {
  width: 60%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  border-radius: 20px;
  border: 0.8px solid rgba(0, 0, 0, 0.7);
  background: #fff;
  padding: 20px;
}

.business-summary-detail > .summary-details-combination > .card-1 > .shipping-details {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items:flex-start;
    gap: 15px;
    border-radius: 10px;
    background: #f5f5f5;
    padding: 15px;
}

.business-summary-detail > .summary-details-combination > .card-1 > .shipping-details > span {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.business-summary-detail > .summary-details-combination > .card-1 > .shipping-details > span > p {
    font-family: Montserrat;
    font-weight: 400;
    font-size: 14px;
    color: #202020;
}

.business-summary-detail > .summary-details-combination > .card-2 {
  width: 40%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  border-radius: 20px;
  border: 0.8px solid rgba(0, 0, 0, 0.7);
  background: #fff;
  padding: 20px;
}

.business-summary-detail > .summary-details-combination > .card-2 > .wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
    border-bottom:1px solid #e0e0e0;

}
.business-summary-detail > .summary-details-combination > .card-2 > .wrapper > h3 {
    color: rgba(33, 33, 33, 0.70);
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
}

.business-summary-detail > .summary-details-combination > .card-2 > .wrapper > .order-icon {
    color: #d0300e;
}

.business-summary-detail > .summary-details-combination > .card-2 > span {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.business-summary-detail > .summary-details-combination > .card-2 > span > p {
    font-family: Montserrat;
    font-weight: 400;
    font-size: 14px;
    color: #202020;
}
.business-summary-detail > .summary-details-combination > .card-2 > span > h3 {
    font-family: Strike;
    font-weight: 400;
    font-size: 14px;
    color: #202020;
}

.business-summary-detail > .summary-details-combination > .card-2  > .btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.business-summary-detail > .summary-details-combination > .card-2  > .btn > button:nth-child(1) {
display: flex;
width: 160px;
height: 55px;
justify-content: center;
align-items: center;
border-radius: 38px;
background:transparent;
border:1px solid  #D0300E;
font-family: Montserrat;
}

.business-summary-detail > .summary-details-combination > .card-2  > .btn > button:nth-child(2) {
display: flex;
width: 160px;
height: 55px;
justify-content: center;
align-items: center;
border-radius: 38px;
background:#D0300E;
border:none;
font-family: Montserrat;
font-size: 14px;
color: #fff;
}


.insufficient-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 15px;
  z-index: 1000;
  padding: 10px;
}


.insufficient-overlay > .message-card {
  color: white;
  font-size: 1.5rem;
  text-align: center;
  font-family: Montserrat;
}

.insufficient-overlay > .wallet-balance {
  width: 580px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 15px;
  background: #fff;
  padding: 20px;
  border-radius: 20px;
}


.insufficient-overlay > .wallet-balance > span {
width: 100%;
display: flex;
justify-content: center;
gap: 50px;
}


.insufficient-overlay > .wallet-balance > span > p {
  font-family: Montserrat;
  color: #202020;
}

.insufficient-overlay > .wallet-balance > .btn {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.insufficient-overlay > .wallet-balance > .btn  > button:nth-child(1) {
  display: flex;
  width: 100px;
  height: 40px;
  justify-content: center;
  align-items: center;
  border-radius: 38px;
  background:transparent;
  border:1px solid  #D0300E;
  font-family: Strike;
  font-size: 14px;
  }

  .insufficient-overlay > .wallet-balance > .btn  > button:nth-child(2) {
  display: flex;
  width: 100px;
  height: 40px;
  justify-content: center;
  align-items: center;
  border-radius: 38px;
  background:#D0300E;
  border:none;
  font-family: Montserrat;
  font-size: 14px;
  color: #fff;
  }
  


@media screen and (max-width:896px) {
  .business-summary-detail {
    padding: 70px 10px 20px 10px;
  }

  .summary-details-combination {
    flex-direction: column;
  }

  .business-summary-detail > .summary-details-combination > .card-1 {
    width: 100%;
    padding: 10px;
  }

  .business-summary-detail > .summary-details-combination > .card-1 > svg {
    width: 100%;
    padding: 10px;
  }

  .business-summary-detail > .summary-details-combination > .card-2 > span > p {
    font-size: 12px;
  }

  .business-summary-detail > .summary-details-combination > .card-2 {
    width: 100%;
    padding: 10px;
  }

  .business-summary-detail > .summary-details-combination > .card-2  > .btn {
    margin-top: 15px;
  }

  .business-summary-detail > .summary-details-combination > .card-2  > .btn > button:nth-child(1) {
    width: 70px;
    height: 40px;
  }
  .business-summary-detail > .summary-details-combination > .card-2  > .btn > button:nth-child(2) {
    width: 70px;
    height: 40px;
  }

  .insufficient-overlay > .wallet-balance {
    width: 100%;
  }
}





