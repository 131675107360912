* {
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
}

@font-face {
  font-family: Montserrat;
  src: url("../../../public/Montserrat-VariableFont_wght.ttf");
}
@font-face {
  font-family: Strike;
  src: url("../../../public/ProtestStrike-Regular.ttf");
}

.business-type {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
}

.business-type > .headers {
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 100px;
  padding: 20px;
  align-items: center;
  border-bottom: 1px solid rgba(32, 32, 32, 0.4);
}

.business-type > .headers > p {
  color: #212121;
  font-family: Strike;
  font-size: 26px;
  font-weight: 600;
}

.business-type > .headers > form {
  display: flex;
  width: 280px;
  height: 44px;
  justify-content: space-between;
  padding: 20px;
  align-items: center;
  border-radius: 30px;
  border: 1px solid rgba(32, 32, 32, 0.6);
  gap: 10px;
}

.business-type > .headers > form > input {
  width: 100%;
  border: none;
  outline: none;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  color: #202020;
  background: transparent;
}

.business-type > .wrappers {
  width: 100%;
  display: flex;
  background: #fff;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.business-type > .wrappers > h3 {
  color: #284697;
  font-family: Strike;
  font-size: 100px;
  font-weight: 800;
}

@media screen and (max-width: 896px) {
  .business-type > .wrappers {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    overflow-x: scroll;
  }

  .business-type > .headers {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    height: fit-content;
    flex-direction: column;
    gap: 15px;
    padding: 0px;
    border: none;
  }
  .business-type > .headers > h3 {
    display: none;
  }

  .business-type > .headers > form {
    width: 100%;
  }

  .business-type > .wrapper > h3 {
    font-size: 40px;
  }
}
