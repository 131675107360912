* {
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
}

@font-face {
  font-family: Strike;
  src: url(/public/ProtestStrike-Regular.ttf);
}

@font-face {
  font-family: Montserrat;
  src: url(/public/Montserrat-VariableFont_wght.ttf);
}
@font-face {
  font-family: Montserrat Alternates;
  src: url(/public/MontserratAlternates-Regular.ttf);
}

.home {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.home-container1 {
  width: 100%;
  height: 653px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("https://i.ibb.co/j5gMm6L/Downloader-2.png");
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-overlay {
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0, 0.3);
  padding-top: 50px;
  display: flex;
  justify-content: center;
}

.home-overlay > .wrapper {
  width: 85%;
  display: flex;
  justify-content: space-between;
}

.wrapper > .home-con1 {
  width: 60%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  padding-left: 10px;
}

.wrapper > .home-con1 > h2 {
  color: #fff;
  font-family: Strike;
  font-size: 52px;
  font-weight: 400;
  line-height: 120.2%;
  width: 594px;
  margin-bottom: 20px;
}

.wrapper > .home-con1 > p {
  color: #fff;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  line-height: 130.2%;
  margin-bottom: 50px;
  width: 77%;
}

.home-overlay > .wrapper > .img-brand {
  width: 40%;
  height: 100%;
  background-image: url("https://i.ibb.co/W0N71bS/Downloader-3-2x.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.home-con1 > form {
  width: 442px;
  height: 50px;
  display: flex;
  justify-content: space-between;
  border-radius: 38px;
  border: 1px solid #fff;
  background-color: #fff;
  padding: 7px 7px 7px 20px;
}

.home-con1 > form > input {
  height: 100%;
  background: transparent;
  border: none;
  outline: none;
  width: 100%;
  font-family: Montserrat;
}

.home-con1 > form > button {
  display: flex;
  width: 36px;
  height: 36px;
  padding: 9px;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  background: #d0300e;
  border: none;
  cursor: pointer;
}

.home-con1 > form > button > svg {
  width: 18px;
  height: 18px;
}

@media screen and (max-width: 768px) {
  .wrapper > .home-con1 {
    width: 100%;
  }

  .home-overlay {
    width: 100%;
  }

  .home-overlay > .wrapper {
    width: 100%;
    gap: 0;
  }

  .home-overlay > .wrapper > .img-brand {
    width: 100%;
    height: 600px;
    position: absolute;
    right: 0%;
    background-size: 100% 100%;
    display: none;
  }
}

@media screen and (max-width: 650px) {
  .home-container1 {
    width: 100%;
    height: 300px;
  }

  .home-overlay {
    width: 100%;
  }

  .home-overlay > .wrapper {
    width: 97%;
    gap: 0;
  }

  .wrapper > .home-con1 > h2 {
    color: #fff;
    font-size: 22px;
    font-weight: 700;
    line-height: 120.2%;
    width: 238px;
  }

  .home-con1 > form {
    display: none;
  }

  .wrapper > .home-con1 {
    width: 100%;
    height: fit-content;
  }

  .wrapper > .home-con1 > p {
    font-size: 10px;
    width: 217px;
  }

  .home-overlay > .wrapper > .img-brand {
    width: 166.667px;
    height: 250px;
    position: absolute;
    right: 0%;
    background-size: 100% 100%;
    display: block;
  }
}

.home-con3 {
  width: 50%;
  display: flex;
  align-items: center;
  gap: -1px;
  transform: translateY(-40%);
  justify-content: center;
}

.ship-now {
  width: 200px;
  height: 177px;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  gap: 15px;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 10px;
  background: #fff;
  box-shadow: 0px 9px 27px 0px rgba(0, 0, 0, 0.05);
}

.ship-now > h3 {
  color: #000;
  text-align: center;
  font-family: Montserrat Alternates;
  font-size: 16px;
  font-weight: 600;
  line-height: 123.023%;
}

.get-qoute {
  width: 200px;
  height: 177px;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  gap: 15px;
  justify-content: center;
  align-items: center;
  border-top-right-radius: 10px;
  background: #fff;
  box-shadow: 0px 9px 27px 0px rgba(0, 0, 0, 0.05);
}

.get-qoute > h3 {
  color: #000;
  text-align: center;
  font-family: Montserrat Alternates;
  font-size: 16px;
  font-weight: 600;
  line-height: 123.023%;
}

.market-box {
  width: 200px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 10px;
  gap: 15px;
  box-shadow: 0px 9px 27px 0px rgba(0, 0, 0, 0.05);
  text-decoration: none;
  background-color: #ffcd00;
}

.market-box > h3 {
  color: #000;
  text-align: center;
  font-family: Montserrat Alternates;
  font-size: 16px;
  font-weight: 600;
  line-height: 123.023%;
}

.tracking {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  display: none;
}

.tracking > form {
  width: 442px;
  height: 50px;
  display: flex;
  justify-content: space-between;
  border-radius: 38px;
  border: 1px solid lightgray;
  padding: 7px 7px 7px 20px;
}

.tracking > form > input {
  height: 100%;
  background: transparent;
  border: none;
  outline: none;
  width: 100%;
}

.tracking > form > button {
  display: flex;
  width: 36px;
  height: 36px;
  padding: 9px;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  background: #d0300e;
  border: none;
  cursor: pointer;
}

.tracking > form > button > svg {
  width: 18px;
  height: 18px;
}

.home > .login {
  width: 100%;
  height: 50px;
  border: none;
  margin: 20px 0px;
  display: none;
}

.home > .login > button {
  min-width: 97%;
  background-color: #d0300e;
  color: white;
  height: 100%;
  width: 36px;
  cursor: pointer;
  border-radius: 38px;
  border: none;
  font-family: Montserrat;
  margin: auto;
}

@media screen and (max-width: 768px) {
  .home-con3 {
    width: 100%;
  }

  .home > .login {
    display: block;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (max-width: 650px) {
  .home > .login {
    display: block;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .home-con3 {
    width: 93%;
    transform: translateY(0%);
    margin: 20px 0px;
  }

  .ship-now {
    width: 110px;
    height: 97px;
  }

  .market-box {
    width: 120px;
    height: 120px;
  }

  .get-qoute {
    width: 110px;
    height: 97px;
  }

  .ship-now > h3 {
    font-size: 11px;
  }
  .get-qoute > h3 {
    font-size: 11px;
  }
  .market-box > h3 {
    font-size: 11px;
  }

  .tracking {
    display: block;
    width: 97%;
    margin-top: 20px;
  }

  .tracking > form {
    width: 100%;
  }
}

.home-discover {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 150px 0px;
  align-items: center;
}

.discover-wrapper {
  width: 85%;
  display: flex;
  justify-content: space-between;
}

.discover-wrapper > .box1 {
  width: 545px;
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.discover-wrapper > .box1 > .inner {
  display: flex;
  gap: 99px;
  width: 100%;
}

.discover-wrapper > .box1 > .inner > p {
  color: #d0300e;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 500;
  line-height: 123.023%;
}

.discover-wrapper > .box1 > .inner > h3 {
  color: #000;
  font-family: "Strike";
  font-size: 36px;
  font-weight: 400;
  line-height: 123.023%;
  width: 400px;
}

.discover-wrapper > .box1 > .image {
  width: 100%;
  height: 400px;
}

.discover-wrapper > .box1 > .image > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.discover-wrapper > .box2 {
  width: 420px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.discover-wrapper > .box2 > img {
  width: 100%;
  height: 300px;
  object-fit: cover;
}

.discover-wrapper > .box2 > .words {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.discover-wrapper > .box2 > .words > p {
  color: #000;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  line-height: 130%;
}

.discover-wrapper > .box2 > .words > p:nth-child(2) {
  color: #000;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}

.discover-wrapper > .box2 > .words > a {
  color: #d0300e;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  line-height: 130%;
  text-decoration-line: underline;
  width: fit-content;
}

@media screen and (max-width: 768px) {
  .home-discover {
    padding: 40px 0%;
    justify-content: center;
    align-items: center;
    width: 97%;
  }

  .discover-wrapper {
    flex-direction: column;
    width: 100%;
    gap: 30px;
    margin: auto;
  }

  .discover-wrapper > .box1,
  .discover-wrapper .box2 {
    width: 100%;
  }

  .discover-wrapper > .box1 > .inner {
    flex-direction: column;
    gap: 30px;
  }

  .discover-wrapper > .box1 > .inner > h3 {
    color: #000;
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 600;
    line-height: 123.023%;
    width: 100%;
  }
}

@media screen and (max-width: 650px) {
  .home-discover {
    padding: 40px 0%;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .discover-wrapper {
    flex-direction: column;
    width: 95%;
    gap: 30px;
    margin: auto;
  }

  .discover-wrapper > .box1,
  .discover-wrapper .box2 {
    width: 100%;
  }

  .discover-wrapper > .box1 > .inner {
    flex-direction: column;
    gap: 30px;
  }

  .discover-wrapper > .box1 > .inner > h3 {
    color: #000;
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 600;
    line-height: 123.023%;
    width: 100%;
  }
  .discover-wrapper > .box1 > .inner > p {
    font-size: 12px;
  }

  .discover-wrapper > .box2 > .words > p {
    color: #000;
    font-family: "Montserrat Alternates";
    font-size: 14px;
    font-weight: 500;
    line-height: 130%;
  }
}

.home-container-2 {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-container-2 > .wrapper {
  width: 85%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-container-2 > .wrapper > .box {
  width: 250px;
  height: 330px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  border: 0.5px solid #e0e0e0;
  background: #fff;
  gap: 15px;
  padding: 50px 10px;
}

.home-container-2 > .wrapper > .box > h3 {
  width: 208px;
  color: #000;
  font-family: Montserrat Alternates;
  font-size: 18px;
  font-weight: 600;
  line-height: 123.023%;
}

.home-container-2 > .wrapper > .box > p {
  width: 208px;
  color: #000;
  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 400;
  line-height: 123.023%;
}

.wrapper-circle-mother-1 {
  display: flex;
  width: 50px;
  height: 50px;
  padding: 5px;
  justify-content: center;
  align-items: center;
  border-radius: 60px;
  margin-bottom: 20px;
  background: rgba(58, 82, 238, 0.1);
}

.wrapper-circle-1 {
  display: flex;
  width: 40px;
  height: 40px;
  padding: 7px;
  justify-content: center;
  align-items: center;
  border-radius: 40px;
  background: rgba(58, 82, 238, 0.3);
}

.wrapper-circle-mother-2 {
  display: flex;
  width: 50px;
  height: 50px;
  padding: 5px;
  justify-content: center;
  align-items: center;
  border-radius: 60px;
  margin-bottom: 20px;
  background: rgba(255, 205, 0, 0.1);
}

.wrapper-circle-2 {
  display: flex;
  width: 40px;
  height: 40px;
  padding: 7px;
  justify-content: center;
  align-items: center;
  border-radius: 40px;
  background: rgba(255, 205, 0, 0.3);
}

.wrapper-circle-mother-3 {
  display: flex;
  width: 50px;
  height: 50px;
  padding: 5px;
  justify-content: center;
  align-items: center;
  border-radius: 60px;
  margin-bottom: 20px;
  background: rgba(208, 48, 14, 0.1);
}

.wrapper-circle-3 {
  display: flex;
  width: 40px;
  height: 40px;
  padding: 7px;
  justify-content: center;
  align-items: center;
  border-radius: 40px;
  background: rgba(208, 48, 14, 0.3);
}
.wrapper-circle-mother-4 {
  display: flex;
  width: 50px;
  height: 50px;
  padding: 5px;
  justify-content: center;
  align-items: center;
  border-radius: 60px;
  margin-bottom: 20px;
  background: rgba(4, 142, 125, 0.1);
}

.wrapper-circle-4 {
  display: flex;
  width: 40px;
  height: 40px;
  padding: 7px;
  justify-content: center;
  align-items: center;
  border-radius: 40px;
  background: rgba(4, 142, 125, 0.3);
}
.wrapper-circle-mother-5 {
  display: flex;
  width: 50px;
  height: 50px;
  padding: 5px;
  justify-content: center;
  align-items: center;
  border-radius: 60px;
  margin-bottom: 20px;
  background: rgba(166, 15, 179, 0.1);
}

.wrapper-circle-5 {
  display: flex;
  width: 40px;
  height: 40px;
  padding: 7px;
  justify-content: center;
  align-items: center;
  border-radius: 40px;
  background: rgba(166, 15, 179, 0.3);
}

@media screen and (max-width: 768px) {
  .home-container-2 > .wrapper {
    justify-content: flex-start;
    overflow-x: scroll;
    width: 97%;
  }

  .home-container-2 > .wrapper > .box {
    width: 100%;
  }
}

@media screen and (max-width: 650px) {
  .home-container-2 > .wrapper {
    justify-content: flex-start;
    overflow-x: scroll;
    width: 97%;
  }

  .home-container-2 > .wrapper > .box {
    width: 100%;
  }
}

.home-container-3 {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 102px 0px;
  flex-direction: column;
}

.home-container-3 > span {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
  display: none;
}

.home-container-3 > .wrapper {
  width: 85%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.friend-service-con1 {
  width: 35%;
  height: 606px;
}

.friend-service-con1 > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.friend-service-con2 {
  width: 65%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.friend-service-con2 > h3 {
  color: #000;
  font-family: Strike;
  font-size: 38px;
  font-weight: 400;
}

.friend-service-con2 > P {
  color: #000;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  line-height: 135.7%;
  margin-top: 15px;
}

.friend-box-1 {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
}

.friend-box-1 > p {
  width: 735px;
  color: #202020;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  line-height: 135.7%;
}

.friend-box-1 > span {
  width: 340px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  margin-top: 50px;
}

.friend-box-1 > span > h3 {
  width: 340px;
  font-family: "Montserrat Alternates";
  font-size: 18px;
  font-weight: 700;
}

.friend-square {
  width: 16px;
  height: 16px;
  background: #d0300e;
  margin-top: 4px;
}

.friend-box-2 {
  width: 100%;
  display: flex;
  gap: 30px;
  justify-content: flex-start;
  align-items: flex-start;
}

.friend-card-1 {
  width: 50%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.friend-card-1 > span > h3 {
  font-family: "Montserrat Alternates";
  font-size: 18px;
  font-weight: 700;
}

.friend-card-1 > p {
  color: #202020;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  line-height: 135.7%;
  margin-top: 20px;
}

.friend-card-1 > span {
  width: 340px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  margin-top: 25px;
}

.friend-card-2 {
  width: 50%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.friend-card-2 > span > h3 {
  font-family: "Montserrat Alternates";
  font-size: 18px;
  font-weight: 700;
}

.friend-card-2 > p {
  color: #202020;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  line-height: 135.7%;
  margin-top: 20px;
}

.friend-card-2 > span {
  width: 340px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  margin-top: 25px;
}

@media screen and (max-width: 768px) {
  .home-container-3 {
    padding: 50px 10px;
  }

  .home-container-3 > .wrapper {
    width: 100%;
    flex-direction: column;
    gap: 30px;
  }

  .friend-service-con1 {
    width: 100%;
  }
  .friend-service-con2 {
    width: 100%;
    flex-direction: column;
  }
}

@media screen and (max-width: 650px) {
  .home-container-3 {
    padding: 50px 10px;
  }

  .home-container-3 > span {
    display: block;
    display: flex;
    gap: 10px;
  }

  .home-container-3 > span > h3 {
    color: #000;
    font-family: Montserrat;
    font-size: 22px;
    font-weight: 700;
  }
  .home-container-3 > span > p {
    color: #000;
    font-family: Montserrat;
    font-size: 11px;
    font-weight: 400;
    line-height: 135.7%;
    margin-bottom: 30px;
  }

  .home-container-3 > .wrapper {
    width: 100%;
    flex-direction: column;
    gap: 30px;
  }

  .friend-service-con1 {
    width: 100%;
  }
  .friend-service-con2 {
    width: 100%;
    flex-direction: column;
  }

  .friend-box-1 > span {
    margin: 0px;
  }

  .friend-box-1 > p {
    width: 90%;
  }

  .friend-box-2 {
    flex-direction: column;
  }

  .friend-card-1 {
    width: 100%;
  }
  .friend-card-2 {
    width: 100%;
  }

  .friend-card-2 > span {
    margin: 0px;
  }

  .friend-service-con2 > h3 {
    display: none;
  }
  .friend-service-con2 > p {
    display: none;
  }
}

.home-container4 {
  width: 100%;
  height: 190vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-container4 > .wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}

.getform-container {
  width: 85%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: 50px;
  gap: 20px;
  background: #fff;
  box-shadow: 0px 9px 34px 0px rgba(0, 0, 0, 0.07);
  transform: translateY(39%);
}

.empty-container {
  width: 100%;
  height: 350px;
  background: #ffcd00;
}

.getform-container > form > .input-combination {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 50px;
}

.getform-container > form {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.getform-container > form > .input-combination > span {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.get-quote-con {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
}

.get-quote-con > span {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
}

.get-quote-con > span > h3 {
  color: #d0300e;
  font-family: Montserrat Alternates;
  font-size: 38px;
  font-weight: 700;
}
.get-quote-con > span > p {
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
}

.getform-container > form > .input-combination > span > label {
  width: 100%;
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  margin: 10px 0px;
}

.getform-container > form > .input-combination > span > input {
  width: 100%;
  height: 50px;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
  background: #fff;
  margin: 10px 0px;
  outline: none;
  color: #b5b5b5;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  padding-left: 10px;
  background-color: transparent;
}
.getform-container > form > .input-combination > span > select {
  width: 100%;
  height: 50px;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
  background: #fff;
  margin: 10px 0px;
  outline: none;
  color: #b5b5b5;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
}

.getform-container > form > button {
  width: 100%;
  height: 55px;
  border-radius: 30px;
  background: #d0300e;
  color: #fff;
  border: none;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  margin: 50px 0px;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .home-container4 {
    padding: 0px;
  }

  .home-container4 > .wrapper {
    width: 100%;
    padding: 0px;
  }

  .getform-container {
    width: 98%;
    padding: 5px 10px;
    transform: translateY(15%);
  }

  .empty-container {
    height: 70px;
  }
}

@media screen and (max-width: 650px) {
  .home-container4 {
    padding: 0px;
    height: fit-content;
  }

  .home-container4 > .wrapper {
    width: 100%;
    padding: 0px;
  }

  .getform-container {
    width: 98%;
    padding: 5px 10px;
    transform: translateY(5%);
  }

  .get-quote-con > span {
    width: 100%;
  }

  .getform-container > form > .input-combination {
    flex-direction: column;
    gap: 10px;
  }

  .getform-container > form > button {
    width: 100%;
    margin: 20px 0px;
  }

  .empty-container {
    height: 70px;
  }
}

.home-blog-container {
  width: 100%;
  display: flex;
  height: 795px;
  padding: 100px 70px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #ffcd00;
}

.home-blog-container > span {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.home-blog-container > span > h3 {
  width: 35%;
  color: #121212;
  font-family: Montserrat;
  font-size: 32px;
  font-style: normal;
  font-weight: 800;
}

.home-blog-container > span > a {
  width: 200px;
  display: flex;
  padding: 20px;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  background: #121212;
  color: #fff;
  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 700;
  text-decoration: none;
}

.home-blog-line {
  width: 100%;
  height: 1px;
  background: #121212;
  margin: 25px 0px;
}

.home-blog-con {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 50px;
}

.home-blog-con > h3 {
  display: none;
}

.home-blog-con > a {
  display: none;
}

.home-blog-con > .home-blog-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}

.home-blog-con > .home-blog-box > h3 {
  color: #121212;
  font-family: "Montserrat Alternates";
  font-size: 26px;
  font-weight: 500;
}
.home-blog-con > .home-blog-box > p {
  color: rgba(18, 18, 18, 0.8);
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.home-blog-con > .home-blog-box > img {
  width: 100%;
  height: 280px;
  object-fit: cover;
}

@media screen and (max-width: 768px) {
  .home-blog-container {
    height: fit-content;
    padding: 30px 10px;
  }

  .home-blog-container > span {
    margin-top: 50px;
  }

  .home-blog-container > span > h3 {
    font-size: 18px;
  }

  .home-blog-con {
    gap: 10px;
  }
}

@media screen and (max-width: 650px) {
  .home-blog-container {
    height: fit-content;
    padding: 30px 10px;
  }


  .home-blog-container > span > a {
    display: none;
  }

  .home-blog-container > span > h3 {
    display: none;
  }

  .home-blog-con {
    flex-direction: column;
    gap: 30px;
  }

  .home-blog-con > h3 {
    display: block;
    color: #202020;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .home-blog-con > a {
    text-decoration: none;
    color: #fefefe;
    text-align: center;
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 600;
    display: block;
    background: #121212;
    border-radius: 50px;
    padding: 10px 25px;
    margin: 20px 0px;
  }
}

.home-faq-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 100px 0px 70px 0px;
  margin-top: 300px;
  /* background-color: red; */
}

.home-faq-container > h3 {
  color: #202020;
  font-family: Strike;
  font-size: 36.604px;
  font-weight: 400;
  margin: 24px 0px;
}

.home-faq-container > p {
  color: rgba(32, 32, 32, 0.8);
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
}

.home-faq-container > a {
  display: flex;
  width: 200px;
  height: 60px;
  padding: 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 75px;
  text-decoration: none;
  color: #121212;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  background: #ffcd00;
}

.home-faq-container > .wrapper {
  width: 90%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 30px 0px;
}

.faq-container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  padding: 20px;
}

.question-container {
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 20px 0px;
  gap: 32px;
  transition: 0.3s ease-out;
  border-bottom: 1px solid lightgray;
}



.question-container > span {
  width: 100%;
  height: 50px;
  display: flex;
  gap: 10px;
  align-items: center;
}

.question-container > span > h3 {
  width: 100%;
  color: rgba(32, 32, 32, 0.8);
  font-family: Montserrat;
  font-size: 26px;
  font-weight: 400;
  line-height: 39px;
}

.question-container > span > .fa-solid {
  color: rgba(32, 32, 32, 0.8);
  font-size: 26px;
}


.answer-container {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0px 35px;
  transition: 0.3s ease-out;
}


.answer-container > p {
  color: rgba(32, 32, 32, 0.8);
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.8rem;
}


@media screen and (max-width: 768px) {
  .home-faq-container {
    padding: 60px 10px 20px 10px;
    margin-top: 250px;
  }

  .home-faq-container > .wrapper {
    width: 100%;
    margin: 0px;
  }


  .home-faq-container > h3 {
    color: #121212;
    text-align: center;
    font-family: Montserrat;
    font-weight: 700;
    margin-top: 100px;
  }

  .home-faq-container > a {
    margin: 30px 0px;
  }

}



@media screen and (max-width:650px) {
  .home-faq-container {
    padding:0px 10px 20px 10px;
    margin-top: 0px;
  }

  .home-faq-container > .wrapper {
    width: 100%;
    margin: 0px;
  }

  .home-faq-container > a {
    margin: 30px 0px;
  }

  .home-faq-container > h3 {
    color: #121212;
    text-align: center;
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 700;
  }

  .home-faq-container > p {
    color: rgba(32, 32, 32, 0.8);
    text-align: center;
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 400;
    line-height: 27px;
  }

  .faq-container {
    width: 100%;
    padding: 0px;
  }

  .question-container {
    gap: 10px;
  }

  .question-container > span > h3 {
    color: rgba(32, 32, 32, 0.8);
    font-family: Montserrat;
    font-size: 11px;
    font-weight: 400;
    line-height: normal;
  }
  .answer-container > p {
    color: rgba(32, 32, 32, 0.8);
    font-family: Montserrat;
    font-size: 10px;
    font-weight: 400;
    line-height: 1.5rem;
  }

}


.tracking-mother-card {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0%;
  background: rgba(18, 18, 18, 0.6);
  z-index: 100;
  padding: 50px 20px;
  overflow-y: scroll;
}

.tracking-mother-card > .wrapper {
  width: 970px;
  min-height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 20px;
  background: #fff;
  padding: 30px 40px;
  margin: auto;
}

.wrapper-span {
  width: 100%;
  display: flex;
  align-items: flex-start;
}

.wrapper-span > p {
  color: #000;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  margin-top: 20px;
}

.tracking-mother-card > .wrapper > span {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tracking-mother-card > .wrapper > span > h3 {
  color: #284697;
  font-family: Montserrat;
  font-size: 26px;
  font-weight: 600;
}

.tracking-from {
  width: 100%;
  height: 94px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
  border-radius: 10px;
  margin: 10px 0px;
  border: 1px solid #e0e0e0;
  background: #fff;
}

.tracking-from > h3 {
  color: #284697;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 700;
}
.tracking-from > p,
.tracking-to > p {
  font-family: Montserrat;
  color: rgba(32, 32, 32, 0.9);
  font-size: 16px;
  font-weight: 400;
}

.tracking-to {
  width: 100%;
  height: 94px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
  border-radius: 10px;
  margin: 10px 0px;
  border: 1px solid #e0e0e0;
  background: #fff;
}

.tracking-to > h3 {
  color: #284697;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 700;
}

.tracking-history {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
  margin-top: 54px;
}

.tracking-history > h3 {
  color: #284697;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
}

.tracking-span {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 100px;
}

.tracking-span > span {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.tracking-span > span > h3 {
  color: rgba(32, 32, 32, 0.9);
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 700;
}

.tracking-span > span > p {
  color: rgba(32, 32, 32, 0.9);
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
}

.delivery-date {
  width: 100%;
}

.delivery-date > h3 {
  color: rgba(32, 32, 32, 0.9);
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 700;
}

.delivery-date > p {
  color: rgba(32, 32, 32, 0.9);
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
}

.tracking-mother-card > .wrapper > span > button {
  background-color: #d0300e;
  color: white;
  border: none;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tracking-status {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}

.tracking-history > h2 {
  color: rgba(32, 32, 32, 0.9);
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 700;
}

.tracking-status > ul {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
}

.tracking-status > ul > .box-1 {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.tracking-status > ul .box-1 > p {
  color: rgba(32, 32, 32, 0.9);
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
}
.tracking-status > ul .box-1 > p > .tracking-icon {
  width: 14px;
  height: 14px;
  margin-right: 5px;
  vertical-align: middle;
}
.tracking-status > ul .box-1 > p > .delivered-icon {
  width: 16px;
  height: 16px;
  margin-right: 5px;
  vertical-align: middle;
  color: #0DBA1E;
}

.tracking-status > ul .box-1 > p > .delivery-failed-icon {
  width: 16px;
  height: 16px;
  margin-right: 5px;
  vertical-align: middle;
  color: #d0300e ;
}

.tracking-status > ul .box-1 > h3 {
  color: rgba(32, 32, 32, 0.9);
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
}

.tracking-status > ul > p {
  color: #0dba1e;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 400;
}

@media screen and (max-width: 650px) {
  .tracking-mother-card {
    padding: 10px;
  }
  .tracking-mother-card > .wrapper {
    width: 100%;
    padding: 30px 10px;
  }

  .tracking-span {
    gap: 10px;
  }

  .tracking-from {
    padding: 10px;
  }
  .tracking-to {
    padding: 10px;
  }

  .tracking-history > h3 {
    font-size: 10px;
  }

  .tracking-status {
    flex-direction: column;
  }

  .tracking-span > span > h3 {
    font-size: 11px;
  }

  .delivery-date > h3 {
    font-size: 11px;
  }
}

.error {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 15px;
}

.error > p {
  color: #121212;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 400;
}

.error > button {
  display: flex;
  width: 120px;
  height: 40px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border: 1px solid rgba(32, 32, 32, 0.5);
  background: #fefefe;
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
}
