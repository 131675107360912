* {
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
}

@font-face {
  font-family: Strike;
  src: url(/public/ProtestStrike-Regular.ttf);
}

@font-face {
  font-family: Montserrat;
  src: url(/public/Montserrat-VariableFont_wght.ttf);
}

@font-face {
  font-family: Montserrat Alternates;
  src: url(/public/MontserratAlternates-Regular.ttf);
}

.authentication {
  width: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-left: 50px;
}

.sign-up {
  width: 93px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  border-radius: 38px;
  background-color: #ffcd00;
  font-weight: bold;
  color: black;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
}
.sign-up-mother {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

}

.sign-up-mother > .card-1 {
  width: 50%;
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('https://res.cloudinary.com/dmk6amr96/image/upload/v1739442108/GetPaidStock.com_-67a4db21c26e8_1_nsscal.jpg');
}

.sign-up-mother > .card-1 > .login-image-overlay {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  background: rgba(0, 0, 0, 0.3);
  padding: 100px 0px 0px 50px;
}

.sign-up-mother > .card-1 > .login-image-overlay > p {
  color: #ffffff;
  font-family: Montserrat;
  font-weight: 500;
  font-size: 42px;
  line-height: 55.02px;
  width: 512px;
}


.sign-up-mother > .card-2 {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items:center;
  flex-direction: column;
  padding: 0px 30px;
  padding: 0px 100px 0px 100px;
}

.sign-up-mother > .card-2 > h3 {
  color: #1f4798;
  font-family: Strike;
  font-size: 26px;
  font-weight: 700;
  margin: 30px 0px;
}

.sign-up-mother > .card-2 > .switch {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.sign-up-mother > .card-2 > .switch > button {
  padding:10px 30px;
  background: transparent;
  font-family: Montserrat;
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
  font-weight: 800;
  color: #202020;
}


.sign-up-mother > .card-2 > .switch button.active-btn {
  background-color: #1f4798;
  color: white;
}





.sign-up-form {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding: 20px 0px;  
}

.sign-up-form > h3 {
  color: #1f4798;
  font-family: Montserrat;
  font-size: 26px;
  font-weight: 700;
  margin: 30px 0px;
}

.sign-up-form > form {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}

.sign-up-form > form > p {
  color: #202020;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
}

.sign-up-form > form > p > span {
  color:#D0310E;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  text-decoration-line: underline;
  cursor: pointer;
}



.sign-up-form > form > span {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.sign-up-form > form > span > label {
  /* color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400; */
  color: #1f4798;
  font-size: 14px;
  font-family: Strike;
  font-weight: 400;
}

.sign-up-form > form > span > input {
  width: 100%;
  height: 50px;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
  background: transparent;
  outline: none;
  margin: 5px 0px;
  padding-left: 10px;
  font-family: Montserrat;
}

.input-combination-1 {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.input-combination-1 > span > label {
  /* color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400; */
  color: #1f4798;
  font-size: 14px;
  font-family: Strike;
  font-weight: 400;
}

.input-combination-1 > span {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.input-combination-1 > span > input {
  width: 100%;
  height: 50px;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
  background: transparent;
  outline: none;
  margin: 5px 0px;
  padding-left: 10px;
  font-family: Montserrat;
}

.input-combination-2 {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  margin: 5px 0px;
}

.input-combination-2 > input {
  width: 100%;
  height: 100%;
  background: transparent;
  outline: none;
  margin: 5px 0px;
  padding-left: 10px;
  border: 1px solid #e0e0e0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.zip-code {
  width: 100px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border: 1px solid #e0e0e0;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.pass {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid #e0e0e0;
  margin: 5px 0px;
  padding: 0px 10px;
}

.pass > input {
  width: 100%;
  height: 100%;
  padding-left: 10px;
  outline: none;
  border: none;
}

.sign-up-form > form > button {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  margin: 5px 0px;
  border-radius: 80px;
  background: #d0300e;
  cursor: pointer;
  color: #fff;
  font-family: Montserrat Alternates;
  font-size: 16px;
  font-weight: 700;
}

.sign-up-form > form > button:disabled {
  /* background-color: #f36c4d; */
  cursor: not-allowed;
}



@media screen and (max-width: 896px) {
  .sign-up {
    display: none;
  }

  .sign-up-mother > .card-1 {
    display: none;
  }
  .sign-up-mother > .card-2 {
    width: 100%;
    padding: 0px;
  }

  .sign-up-form {
    padding: 10px;
  }

  .sign-up-form > form {
    width: 100%;
    gap: 10px;
  }

  .sign-up-form > form > p {
    margin-bottom: 20px;
  }

  .input-combination-1 {
    flex-direction: column;
    gap: 10px;
  }


}
