* {
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
}

@font-face {
  font-family: Strike;
  src: url(/public/ProtestStrike-Regular.ttf);
}

@font-face {
  font-family: Montserrat;
  src: url(/public/Montserrat-VariableFont_wght.ttf);
}
@font-face {
  font-family: Montserrat Alternates;
  src: url(/public/MontserratAlternates-Regular.ttf);
}

.truck-selection {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  padding: 20px 0px;
}

.truck-selection > .wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  padding: 15px 100px;
  border-bottom: 0.5px solid rgba(32, 32, 32, 0.3);
  background: #fff;
}

.truck-selection > .wrapper > p {
  color: #202020;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  line-height: 27px;
}
.truck-selection > .wrapper > h3 {
  color: #284697;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  line-height: 27px;
}

.truck-selection-card-1 {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: #fff;
  padding: 20px 100px;
  box-shadow: 0px 10px 24px 0px rgba(0, 0, 0, 0.07);
}

.truck-selection-card-1 > form {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
}

.truck-selection-card-1 > form > span {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.truck-selection-card-1 > form > span > select {
  width: 120px;
  height: 36px;
  padding: 5px;
  border-radius: 10px;
  border: 0.5px solid rgba(32, 32, 32, 0.3);
  background: #fff;
  outline: none;
  font-family: Montserrat;
  font-size: 13px;
  font-weight: 400;
  color: #284697;
}

.truck-selection-card-1 > form > span > button {
  width: 120px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: none;
  background: #d0300e;
  outline: none;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  color: white;
}

.truck-selection-card-1 > form > .btn {
  display: none;
}

.truck-selection-card-2 {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
  padding: 20px 100px;
}

.truck-selection-card-2 > span {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}

.truck-selection-card-2 > h3 {
  color: #000;
  text-align: center;
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 500;
}

.truck-selection-card-2 > span > .wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
  border-radius: 20px;
  border: 1px solid rgba(32, 32, 32, 0.3);
  background: #fff;
  text-decoration: none;
}

.truck-selection-card-2 > span > .wrapper > img {
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
}

.truck-selection-info {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
  padding: 15px;
}

.truck-selection-info > h3 {
  color: #202020;
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 400;
  line-height: 27px;
}
.truck-selection-info > h4 {
  color: #202020;
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 700;
  line-height: 27px;
}

.truck-selection-info > p {
  color: rgba(32, 32, 32, 0.8);
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.truck-selection-info > p > span {
  color: #1f4798;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
}

.truck-selection-span-2 {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 10px;
}

.truck-selection-card-2 > h3 {
  color: #202020;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 700;
}

@media screen and (max-width: 896px) {
  .truck-selection {
    padding: 10px;
  }

  .truck-selection > .wrapper {
    flex-direction: column;
    padding: 0%;
  }

  .truck-selection-card-1 {
    padding: 20px 10px;
  }

  .truck-selection-card-1 > form {
    flex-direction: column;
    gap: 30px;
  }

  .truck-selection-card-1 > form > span > select {
    width: 100%;
  }

  .truck-selection-card-1 > form > span > button {
    display: none;
  }

  .truck-selection-card-1 > form > .btn {
    display: block;
    width: 100%;
  }

  .truck-selection-card-1 > form > .btn > button {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    outline: none;
    cursor: pointer;
    font-weight: 600;
    font-family: Montserrat;
    background: #d0300e;
    color: #fff;
    font-size: 18px;
    border-radius: 80px;
  }

  .truck-selection-info {
    width: 100%;
  }

  .truck-selection-span-1 {
    padding: 0px 10px;
  }

  .truck-selection-card-2 {
    padding: 20px 0px;
  }

  .truck-selection-card-2 > span {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* display: grid;
  grid-template-columns: repeat(2,1fr); */
  }
}

.search-truck-overlay {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  top: 0%;
  left: 0%;
  z-index: 101;
  position: fixed;
  padding: 50px;
  background: rgba(32, 32, 32, 0.5);
}

.search-truck-overlay > span {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.search-truck-overlay > span > button {
  background: transparent;
  border: none;
  outline: none;
  font-size: 25px;
  color: white;
}

.search-truck-overlay > .search-results {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 15px;
  background: #fff;
  padding: 50px;
  border-radius: 10px;
}

.search-truck-overlay > .search-results > .wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 15px;
  text-decoration: none;
  color: #000;
  border-radius: 20px;
  border: 1px solid rgba(32, 32, 32, 0.3);
}

.search-truck-overlay > .search-results > .wrapper > img {
  width: 100%;
  height: 100px;
  object-fit: cover;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
}

.search-truck-overlay > .search-results > .wrapper > span {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 15px;
  padding: 10px;
}

.search-truck-overlay > .search-results > .wrapper > span > h3 {
  color: #202020;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
}
.search-truck-overlay > .search-results > .wrapper > span > h4 {
  color: #202020;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 700;
}
.search-truck-overlay > .search-results > .wrapper > span > p {
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
}

@media screen and (max-width: 896px) {
  .search-truck-overlay {
    overflow-y: scroll;
    padding: 10px;
  }

  .search-truck-overlay > .search-results {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 10px;
  }
}
