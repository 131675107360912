* {
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
}

@font-face {
  font-family: Strike;
  src: url("../../../public/ProtestStrike-Regular.ttf");
}

@font-face {
  font-family: Montserrat;
  src: url("../../../public/Montserrat-VariableFont_wght.ttf");
}

.business-create-category {
  padding: 100px 20px 20px 270px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #f9f9f9;
}

.business-create-category > .header {
  display: flex;
  width: 100%;
  height: 100px;
  padding: 20px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(32, 32, 32, 0.4);
}

.business-create-category > .header > p {
  color: #284697;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 700;
  line-height: 83.523%;
}

.business-create-category > .header > button {
  display: flex;
  padding: 10px 20px;
  gap: 10px;
  border-radius: 40px;
  background: #284697;
  color: #fff;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  border: none;
  outline: none;
  cursor: pointer;
}


.business-user-category {
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.business-user-category > .loading-error {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}


.business-user-category > .loading-error  > p {
  color:  #000;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 400;
}

.business-user-category > p {
  color:  #000;
  font-family: Strike;
  font-size: 18px;
  font-weight: 400;
  margin: 5px 0px;

}


.business-user-category > .category-item {
  width: 100%;
  display: flex;
  padding: 13px 0px;
  border-bottom: 1px solid #e0e0e0;
  background: #fdfdfd;
  justify-content: space-between;
  align-items: center;
}


.business-user-category > .category-item > .card {
  display: flex;
  flex-direction: column;
  gap: 5px;
}


.business-user-category > .category-item > .card > p:nth-child(1) {
  color: #000;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
}


.business-user-category > .category-item > .card > p:nth-child(2) {
  color: #000;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
}


.business-user-category > .category-item > .btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}


.business-user-category > .category-item > .btn > button:nth-child(1) {
  display: flex;
  width: 120px;
  height: 40px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  border: 1px solid rgba(32, 32, 32, 0.5);
  background: #fefefe;
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
}

.business-user-category > .category-item > .btn > button:nth-child(2) {
  display: flex;
  width: 120px;
  height: 40px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  background: #d0310e;
  color: #fff;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  border: none;
  cursor: pointer;
}

@media screen and (max-width:896px) {
  .business-create-category {
    padding: 45px 10px 20px 10px;
  }

  .business-create-category > .header {
    height: 80px;
    padding: 0px;
    margin: 10px 0px;
  }

  .business-create-category > .header > p {
    font-size: 14px;
  }

  .business-create-category > .header > button {
    padding: 10px 20px;
    font-size: 10px;
  }

  .business-user-category {
    padding: 0px;
  }

  .business-user-category > .category-item > .btn > button:nth-child(1) {
    width: 70px;
    font-size: 13px;
  }
  .business-user-category > .category-item > .btn > button:nth-child(2) {
    width: 70px;
    font-size: 13px;
  }
}


.business-create-category-overlay {
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: flex-start;
  flex-shrink: 0;
  background: rgba(32, 32, 32, 0.5);
  position: fixed;
  top: 0%;
  right: 0%;
  z-index: 101;
  overflow-y: scroll;
  padding: 50px 0px;
}

.business-create-category-overlay  > form {
  width: 600px;
  border-radius: 20px;
  background: #fff;
  padding: 20px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}

.business-create-category-overlay  > form > p {
  padding: 10px;
  border-bottom: 1px solid #e0e0e0;
  color: #202020;
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 600;
  line-height: 25px;
  width: 100%;
  text-align: center;
}

.business-create-category-overlay  > form > label {
  width: 100%;
  padding: 0px 50px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  font-family: Montserrat;
  color: #202020;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
}

.business-create-category-overlay  > form > label > input {
  width: 500px;
  height: 44px;
  border-radius: 30px;
  border: 1px solid rgba(32, 32, 32, 0.5);
  background: #fff;
  padding-left: 20px;
  outline: none;
  font-family: Montserrat;
}

.business-create-category-overlay  > form > .btn {
  width: 100%;
  padding: 0px 50px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}

.business-create-category-overlay > form > .btn > button:nth-child(1) {
  width: 100px;
  height: 44px;
  padding: 10px;
  border-radius: 35px;
  background: #fff;
  color: rgba(32, 32, 32, 0.7);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 25px;
  border: none;
  cursor: pointer;
}

.business-create-category-overlay  > form > .btn > button:nth-child(2) {
  width: 100px;
  height: 44px;
  padding: 10px;
  border-radius: 35px;
  border-radius: 35px;
  background: #b5b5b5;
  color: #fff;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 25px;
  border: none;
  cursor: pointer;
}

.business-create-category-overlay  > form > .btn > button:nth-child(2):disabled {
  cursor: not-allowed;
  background-color: #527bec;
}
.business-create-category-overlay > form > span {
  color: green;
  font-family: Montserrat;
}


.business-description-fields {
  width: 500px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.business-description-fields > .wrapper {
  width: 100%;
  display: flex;
  justify-content:flex-start;
  align-items: flex-start;
  gap: 5px;
}

.business-description-fields > .wrapper > input {
  width: 100%;
  height: 44px;
  border-radius: 30px;
  border: 1px solid rgba(32, 32, 32, 0.5);
  background: #fff;
  padding-left: 20px;
  outline: none;
  font-family: Montserrat;
}


.business-description-fields > .wrapper > button {
  width: fit-content;
  height: 44px;
  padding: 5px 10px;
  border: 1px solid #d0310e ;
  color: #202020;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  cursor: pointer;
  background: transparent;
  border-radius: 5px;
  
}

.business-description-fields > .wrapper > button > .removal-icon {
  display: none;
}


.business-description-fields > .wrapper > button:hover {
  background:#d0310e;
  color: #fff;
}

.button-add{
  color: #fff;
  font-family: Strike;
  font-size: 14px;
  font-weight: 400;
  line-height: 25px;
  border: none;
  cursor: pointer;
  background-color: #284697;
  width: 83%;
  height: 44px;
  border-radius: 30px;
}


@media screen and (max-width:896px) {
  .business-create-category-overlay {
    padding: 10px;
  }

  
.business-create-category-overlay  > form {
  width: 100%;
  padding: 10px;
}

.business-create-category-overlay  > form > label {
  padding: 0px;
}

.business-create-category-overlay  > form > label > input {
  width: 100%;
}


.business-description-fields {
  width: 100%;
}

.business-description-fields > .wrapper > button {
  border: none;

}

.business-description-fields > .wrapper > button > .removal-icon {
  display: block;
  font-size: 24px;
  color: #d0310e;
}
.business-description-fields > .wrapper > button > p {
  display: none;
}

.button-add {
  width: 100%;
}

}

.business-deleted-container {
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  background: rgba(32, 32, 32, 0.5);
  position: fixed;
  top: 0%;
  right: 0%;
  z-index: 101;
}
.business-deleted-container > .card {
  display: flex;
  padding: 25px 50px;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  border-radius: 10px;
  background: #fff;
}

.business-deleted-container > .card > h3 {
  color: #202020;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 800;
}

.business-deleted-container > .card > p {
  width: 144px;
  height: 27px;
  color: #202020;
  text-align: center;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
  line-height: 118.243%;
}

.business-deleted-container > .card > .btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.business-deleted-container > .card > .btn > button:nth-child(1) {
  display: flex;
  width: 120px;
  height: 40px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  border: 1px solid rgba(32, 32, 32, 0.5);
  background: #fefefe;
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
}

.business-deleted-container > .card > .btn > button:nth-child(2) {
  display: flex;
  width: 120px;
  height: 40px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  background: #d0310e;
  color: #fff;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  border: none;
  cursor: pointer;
}

@media screen  and (max-width:896px){
  .business-deleted-container {
    padding: 10px;
  }

  .business-deleted-container > .card {
    width: 100%;
    padding: 10px;
  }

  .business-deleted-container > .card > p {
    width: 100%;
  }
}

.business-update-container {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: flex-start;
  flex-shrink: 0;
  background: rgba(32, 32, 32, 0.5);
  position: fixed;
  top: 0%;
  right: 0%;
  z-index: 101;
  overflow-y: scroll;
  padding: 50px 0px;
}

.business-update-container  > form {
  width: 600px;
  border-radius: 20px;
  background: #fff;
  padding: 20px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  padding: 20px 40px  ;
}

.business-update-container > form > p {
  padding: 10px;
  border-bottom: 1px solid #e0e0e0;
  color: #202020;
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 600;
  line-height: 25px;
  width: 100%;
  text-align: center;
}

.business-update-container > form > label {
  width: 100%;
  padding: 0px 50px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  font-family: Montserrat;
  color: #202020;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
}

.business-update-container  > form > label > input {
  width: 500px;
  height: 44px;
  border-radius: 30px;
  border: 1px solid rgba(32, 32, 32, 0.5);
  background: #fff;
  padding-left: 20px;
  outline: none;
  font-family: Montserrat;
}
.business-update-container  > form > input {
  width: 100%;
  height: 44px;
  border-radius: 30px;
  border: 1px solid rgba(32, 32, 32, 0.5);
  background: #fff;
  padding-left: 20px;
  outline: none;
  font-family: Montserrat;
}


.update-description-fields {
  width: 100%;
  display: flex;
  justify-content:flex-start;
  align-items: flex-start;
}

.update-description-fields > .wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.update-description-fields > .wrapper > input {
  width: 100%;
  height: 44px;
  border-radius: 30px;
  border: 1px solid rgba(32, 32, 32, 0.5);
  background: #fff;
  padding-left: 20px;
  outline: none;
  font-family: Montserrat;
}

.update-description-fields > .wrapper > button {
  width: fit-content;
  height: 44px;
  padding: 5px 10px;
  border: 1px solid #d0310e ;
  color: #202020;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  cursor: pointer;
  background: transparent;
  border-radius: 5px;
}

.update-description-fields > .wrapper > button:hover {
  background: #d0310e;
  color: #fff;
}

.business-update-container > form > .btn {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
}
.business-update-container > form > .btn > button:nth-child(1) {
  display: flex;
  width: 120px;
  height: 40px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  border: 1px solid rgba(32, 32, 32, 0.5);
  background: #fefefe;
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
}

.business-update-container > form > .btn > button:nth-child(2) {
  display: flex;
  width: 120px;
  height: 40px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  border: 1px solid rgba(32, 32, 32, 0.5);
  background: transparent;
  color: #000;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
}

.business-update-container > form > .btn > button:nth-child(2):hover {
  background: #284697;
  color: #fff;
  border: none;
} 

.business-update-removal-icon {
display: none;
}

.update-button-add{
  color: #fff;
  font-family: Strike;
  font-size: 14px;
  font-weight: 400;
  line-height: 25px;
  border: none;
  cursor: pointer;
  background-color: #284697;
  width: 100%;
  height: 44px;
  border-radius: 30px;
}


@media screen  and (max-width:896px){
  .business-update-container {
    padding: 10px;
  }

  .business-update-container > form {
    width: 100%;
    padding: 10px;
  }

  .update-description-fields {
    width: 100%;
  }
  
  .description-fields > .wrapper > label {
    width: 100%;
  }

  .business-update-removal-icon {
    display: block;
    font-size: 24px;
    color: #d0310e;
  }

  .update-description-fields > .wrapper > button  {
  background: transparent;
  border: none;
  }
  .update-description-fields > .wrapper > button > p {
    display: none;
  }
}



