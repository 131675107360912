* {
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
}

@font-face {
  font-family: Montserrat;
  src: url("../../../public/Montserrat-VariableFont_wght.ttf");
}
@font-face {
  font-family: Strike;
  src: url("../../../public/ProtestStrike-Regular.ttf");
}

.business-package-shipment {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  padding: 100px 20px 20px 276px;
}

.business-package-shipment > .header {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.business-package-shipment > .header > h3 {
  color: #212121;
  font-family: Strike;
  font-size: 26px;
  font-weight: 400;
}

.business-package-shipment > .wrapper {
  width: 40%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
  border-radius: 20px;
  padding: 20px;
  border: 0.6px solid rgba(0, 0, 0, 0.8);
  background: var(
    --bg,
    linear-gradient(
      358deg,
      rgba(255, 255, 255, 0) -10.62%,
      rgba(255, 205, 0, 0.08) 115.73%
    )
  );
}

.business-package-shipment > .wrapper > h2 {
  color: #000;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 600;
}

.business-package-shipment > .wrapper > .create-icon {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.business-package-shipment > .wrapper > .create-icon > span > h3 {
  color: #212121;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 700;
}
.business-package-shipment > .wrapper > .create-icon > span > p {
  color: rgba(33, 33, 33, 0.7);
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 400;
}

.business-package-shipment > .wrapper > .shipment-type-box {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.business-package-shipment > .wrapper > .shipment-type-box > .card {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
  border-radius: 10px;
  border: 0.6px solid rgba(0, 0, 0, 0.6);
  background: #fff;
  text-decoration: none;
  cursor: pointer;
}

.business-package-shipment > .wrapper > .shipment-type-box > .card:hover {
  border: 1.5px solid #284697;
}

.business-package-shipment
  > .wrapper
  > .shipment-type-box
  > .card
  > .shipment-type-icon {
  color: #284697;
  font-size: 24px;
}

.business-package-shipment > .wrapper > .shipment-type-box > .card > h3 {
  color: #000;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
}
.business-package-shipment > .wrapper > .shipment-type-box > .card > h3 {
  color: #000;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
}
.business-package-shipment > .wrapper > .shipment-type-box > .card > p {
  color: rgba(33, 33, 33, 0.7);
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.024px;
}

@media screen and (max-width: 896px) {
  .business-package-shipment {
    padding: 70px 10px 20px 10px;
  }

  .business-package-shipment > .wrapper {
    width: 100%;
    padding: 10px;
  }

  .business-package-shipment > .wrapper > .shipment-type-box {
    flex-direction: column;
  }

  .business-package-shipment > .wrapper > .create-icon > span > h3 {
    font-size: 16px;
  }
  .business-package-shipment > .wrapper > .create-icon > span > p {
    font-size: 12px;
  }
}

.business-delivery-types {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0%;
  right: 0%;
  background: rgba(18, 18, 18, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 101;
}

.business-delivery-types > .card {
  width: 580px;
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  border-radius: 10px;
  background: #fff;
}

.business-delivery-types > .card > span {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.business-delivery-types > .card > span > h3 {
  color: #202020;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
}

.business-delivery-types > .card > p {
  color: #202020;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
}

.business-delivery-types > .card > span > button {
  background: transparent;
  border: none;
  cursor: pointer;
}

.business-delivery-types > .card > form {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 5px;
}

.business-delivery-types > .card > form > label {
  color: #202020;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
}

.business-delivery-types > .card > form > select {
  width: 100%;
  height: 50px;
  color: #202020;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
  background: #fff;
  outline: none;
}

.business-delivery-types > .card > form > button {
  margin-top: 20px;
  background: transparent;
  border:1px solid #e0e0e0;
  border-radius: 30px;
  width: 170px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  font-family: Montserrat;
  color: #284697;
  font-weight: 600;
  font-size: 12px;
}

.business-delivery-types > .card > .next {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.business-delivery-types > .card > .next > button {
  background: #284697;
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  padding: 10px  15px;
  font-family: Montserrat;
}

.business-delivery-types > .card > .shipping-icon-home {
    width: 100%;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 50px;
    background: var(--bg, linear-gradient(358deg, rgba(255, 255, 255, 0.00) -10.62%, rgba(255, 205, 0, 0.08) 115.73%));
  
}


@media screen and (max-width:896px) {
  .business-delivery-types {
    padding:70px 10px 10px 10px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }

  

  .business-delivery-types > .card {
    width: 100%;
    padding: 10px;
  }

  .business-delivery-types > .card > p {
    text-align: center;
  }
}









/* 
.ship-delete > .card > .btn > button:nth-child(1) {
  display: flex;
  width: 120px;
  height: 40px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  border: 1px solid rgba(32, 32, 32, 0.5);
  background: #fefefe;
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
}

.ship-delete > .card > .btn > button:nth-child(2) {
  display: flex;
  width: 120px;
  height: 40px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  background: #d0310e;
  color: #fff;
  font-family: Montserrat;
  border: none;
  cursor: pointer;
  font-size: 14px;
  font-weight: 700;
}

 */
